import axios, { AxiosError, AxiosResponse } from "axios";
import { useRecoilState, useResetRecoilState } from "recoil";
import { ILoggedUser } from "../local-interfaces/local-interfaces";
import { loggedUserAtom } from "../recoil/atoms";
import { enqueueSnackbar } from "notistack";
import { ErrorAutoHide } from "../config/general.config";

export const BASE_URL: string = process.env.REACT_APP_PORT
  ? `${process.env.REACT_APP_APIBASEURL}:${process.env.REACT_APP_PORT}`
  : `${process.env.REACT_APP_APIBASEURL}`;

enum HTTP_METHOD {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

const ERROR_MESSAGES: Record<number, string> = {
  400: "Bad Request: The server could not make the request due to invalid syntax.",
  403: "Forbidden: You do not have permission to access this resource. Please try to log in again",
  404: "Not Found: The requested resource could not be found.",
  409: "Conflict: The request could not be completed due to a conflict with the current state of the target resource.",
  500: "Internal Server Error: The server encountered an unexpected condition.",
  503: "Service Unavailable: The server is currently unable to handle the request due to temporary overloading or maintenance of the server.",
};

const useApiWrapper = () => {
  const [loggedUser] = useRecoilState<ILoggedUser | undefined>(loggedUserAtom);

  const _reset = useResetRecoilState(loggedUserAtom);

  const _commonRequest = (method: HTTP_METHOD, token?: string) => {
    return async (url: string, body: any = {}, params?: Record<string, any>): Promise<AxiosResponse<any, any>> => {
      const config = {
        url: `${BASE_URL}/${url}`,
        method,
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
          "Content-Type": method !== HTTP_METHOD.GET ? "application/json" : "accept",
        },
        ...(method !== HTTP_METHOD.GET && { data: body }),
        ...(params && { params }),
      };

      return await axios(config).catch(handleError);
    };
  };

  const handleError = (error: AxiosError) => {
    if (!error.response) return Promise.reject("Network error. Check your connection.");
    const message = ERROR_MESSAGES[error.response.status] || `Unexpected Error: ${error.response.status}`;
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: ErrorAutoHide
    })
    error.response.status === 403 && _reset()
    return Promise.reject(message);
  };

  return {
    get: _commonRequest(HTTP_METHOD.GET),
    post: _commonRequest(HTTP_METHOD.POST),
    put: _commonRequest(HTTP_METHOD.PUT),
    delete: _commonRequest(HTTP_METHOD.DELETE),
    getWithAuth: _commonRequest(HTTP_METHOD.GET, loggedUser?.token),
    postWithAuth: _commonRequest(HTTP_METHOD.POST, loggedUser?.token),
    putWithAuth: _commonRequest(HTTP_METHOD.PUT, loggedUser?.token),
    deleteWithAuth: _commonRequest(HTTP_METHOD.DELETE, loggedUser?.token),
  };
};

export { useApiWrapper };
