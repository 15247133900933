import { TabContext, TabPanel } from "@mui/lab"
import { FC, useEffect, useState } from "react"
import { IOscService, IQuery } from "../../../common-interfaces/interfaces"
import RenderSplittedString from "./common/RenderSplittedString"
import { deepCopy } from "../../../helpers/utils"
import useService from "../../../hooks/useService"
import { serviceParamsAtom } from "../../../recoil/Atom"
import { useRecoilState } from "recoil"
import { ControlWhiteTextField } from "../../../theme"
// import HandleEndpoint from "./Endpoint/HandleEndpoint"

// const selectStyle = { fill: '#ccc', textAlign: 'center', color: '#ccc !important', letterSpacing: 1, textTransform: 'uppercase', '& .MuiSelect-icon': { fill: '#ccc' } }
// const formControlStyle = { "& .MuiFormLabel-root, .Mui-focused, .MuiInputBase-root": { color: "#ccc !important" } }
// const tabListStyle = { width: '100%', bgcolor: '#222' }
// const tabStyle = { minWidth: '50%', height: '100%', color: '#ccc', fontSize: '17px', letterSpacing: 1 }

const EditOscService: FC<{ oscService: IOscService }> = ({ oscService }) => {
    const SERVICE = useService()

    const [, setServiceParams] = useRecoilState<IQuery[]>(serviceParamsAtom);
    const [port, setPort] = useState<number>(0)
    const [tabValue, setTabValue] = useState<"messageIn" | "messageOut">("messageOut")

    useEffect(() => {
        setServiceParams(oscService[tabValue].params ?? [])
        setPort(oscService[tabValue].port)
    }, [oscService, tabValue])

    // const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    //     setTabValue(newValue);
    // };

    const updateEndpoint = async (params: IQuery[], endpoint: string) => {
        const tmpOscService: IOscService = await deepCopy(oscService)
        tmpOscService[tabValue].endpoint = endpoint
        tmpOscService[tabValue].params = [...params]
        SERVICE.updateServicesWith(tmpOscService, 'oscService')
    }

    const updatePort = async () => {
        const tmpOscService: IOscService = await deepCopy(oscService)
        tmpOscService[tabValue].port = port
        SERVICE.updateServicesWith(tmpOscService, 'oscService')
    }

    return (
        <TabContext value={"messageOut"}>
            {/* <TabList sx={tabListStyle} onChange={handleTabChange} indicatorColor='secondary' textColor='secondary' variant='standard'>
                <Tab label="Command" value="0" sx={tabStyle} />
                <Tab label="Feedback" value="1" sx={tabStyle} />
            </TabList> */}
            <TabPanel value={"messageOut"}>
                {/* <Stack gap={3}> */}
                <RenderSplittedString
                    label={"Address:"}
                    handleUpdate={updateEndpoint}
                    inputString={oscService.messageOut.endpoint}
                    divider={"/"}
                    dividerOnStart={true}
                />
                <ControlWhiteTextField
                    variant="standard"
                    className="input componentInput"
                    type="number"
                    label="PORT:"
                    sx={{ mt: 4 }}
                    value={port}
                    onChange={(e) => setPort(+e.target.value)}
                    onBlur={updatePort}
                    onKeyDown={(e) => {
                        e.stopPropagation();
                        if (e.key === "Enter") {
                            updatePort();
                        }
                    }}
                />
                {/* <HandleEndpoint serviceType='oscService' section='messageOut' endpoint={endpointSet()} divider="/" /> */}
                {/* </Stack> */}
            </TabPanel>
            {/* <TabPanel value={"messageIn"}>
                <HandleEndpoint serviceType='oscService' endpoint={endpointState()} section={'requestState'} />
            </TabPanel> */}
        </TabContext >
    )
}

export default EditOscService

