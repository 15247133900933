import { useRecoilState } from "recoil"
import { Grid } from "@mui/material"
import ColumnLoader from "./ColumnLoader"
import { ADMINTOOOL_COLUMNS, ADMINTOOOL_Value, IAdminToolFilterBy, IAdminToolStore } from "./adminToolConfig"
import { useApiWrapper } from "../../hooks/useApiWrapper"
import { useEffect, useState } from "react"
import { IInstallation } from "../../common-interfaces/interfaces"
import { adminToolFilterByAtom, adminToolSearchQueryAtom, adminToolStoreAtom } from "./AdminToolAtom"
import { deepCopy, PrintError } from "../../helpers/utils"
import handleAdminToolFilterQuery from "./useAdminToolUtils"

const AdminToolTable = () => {

  const { getWithAuth } = useApiWrapper()
  const [adminToolStore, setAdminToolStore] = useRecoilState<IAdminToolStore>(adminToolStoreAtom)
  const [filteredValue, setFilteredValue] = useState<IAdminToolStore>()
  const [filterBy] = useRecoilState<IAdminToolFilterBy>(adminToolFilterByAtom);

  handleAdminToolFilterQuery()


  const init = async () => {
    try {
      const results = await Promise.all(
        ADMINTOOOL_COLUMNS.map(async c => {
          const url = `api/${c}`;
          const res = await getWithAuth(url, {});
          return {
            [c]: res.data.reduce((acc: any, item: any) => {
              acc[item._id] = item;
              return acc;
            }, {})
          };
        })
      );

      const parsedResult: IAdminToolStore = results.reduce((acc, result) => {
        return { ...acc, ...result };
      }, {}) as IAdminToolStore;
      setAdminToolStore(parsedResult)
    } catch (e) {
      PrintError([e])
    }
  };

  useEffect(() => {
    init();
  }, []);

  const filterAdminToolStore = async (): Promise<void> => {
    const store = await deepCopy(adminToolStore)
    const tmpFilteredStore: IAdminToolStore = {
      installation: {},
      configuration: {},
      dashboard: {},
      component: {},
      service: {}
    };

    // Filter installation
    if (store.installation[filterBy.installation.id]) {
      tmpFilteredStore.installation[filterBy.installation.id] = store.installation[filterBy.installation.id];
    }

    // Filter configuration
    if (store.configuration[filterBy.configuration.id]) {
      tmpFilteredStore.configuration[filterBy.configuration.id] = store.configuration[filterBy.configuration.id];
    }

    // Filter dashboard
    filterBy.dashboard.ids.forEach(id => {
      if (store.dashboard[id]) {
        tmpFilteredStore.dashboard[id] = store.dashboard[id];
      }
    });

    // Filter component
    filterBy.component.ids.forEach(id => {
      if (store.component[id]) {
        tmpFilteredStore.component[id] = store.component[id];
      }
    });

    // Filter service
    filterBy.service.ids.forEach(id => {
      if (store.service[id]) {
        tmpFilteredStore.service[id] = store.service[id];
      }
    });
    setFilteredValue(tmpFilteredStore);
  }

  useEffect(() => {
    filterBy ? filterAdminToolStore() : setFilteredValue(adminToolStore)
  }, [filterBy]);

  return (
    <Grid container xs={12} sx={{ height: '100%', width: '100%' }}>
      {
        ADMINTOOOL_COLUMNS.map((c, index) => <ColumnLoader columnIndex={index} value={filteredValue ?? adminToolStore}></ColumnLoader>)
      }

    </Grid>
  )

}
export default AdminToolTable