import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { IDashboard, IInstallation } from "../../../common-interfaces/interfaces";
import { ChangeEvent, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isLoadingAtom, selectedDevicesAtom, selectedInstallationAtom } from "../../../recoil/atoms";
import { deepCopy } from "../../../helpers/utils";
import { useOnSet } from "../../../hooks/useOnSet";
import TooltipOverflow from "../../../common-components/common/TooltipOverflow";
import { uneditableDashboardAtom } from "../../../recoil/Atom";
import useOnCopy from "../../../hooks/useOnCopy";
import { useOnGet } from "../../../hooks/useOnGet";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useApiWrapper } from "../../../hooks/useApiWrapper";


export default function DashFastCreate() {

  const [filteredDashboards, setFilteredDashboards] = useState<IDashboard[]>([]);
  const [selectedDevices] = useRecoilState(selectedDevicesAtom)
  const [isLoading, setIsloading] = useRecoilState<boolean>(isLoadingAtom);
  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [uneditableDash] = useRecoilState<IDashboard[]>(uneditableDashboardAtom)


  const SET = useOnSet();
  const GET = useOnGet()
  const { handleCopyDash } = useOnCopy()
  const { deleteWithAuth } = useApiWrapper()

  const handleSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!uneditableDash) return;
    event.preventDefault();
    const filter = event.target.value;
    const filtered = uneditableDash.filter((dash) => {
      return dash.name.toUpperCase().includes(filter.toUpperCase())
    }
    );
    setFilteredDashboards(filtered);
  };



  const removeIncompatibleDash = async () => {
    if (!uneditableDash) return
    const user = await GET.user()
    const filtered = uneditableDash.filter(dash => {
      const isAdmin = user.role === 'admin'
      // ts si aspetta un IUser ma il BE restituise un objectID
      const installerCreatedThisDash = dash.creator?.some(d => d.toString() === user._id)
      return isAdmin || installerCreatedThisDash

      // || selectedDevices.find(dev => {
      //const scheme = ALLDSP.find(schema => schema.model === dev.product_model)?.scheme
      //  return false//d.compatibleDsp && scheme && d.compatibleDsp.includes(scheme)
      //  })
    }
    )
    setFilteredDashboards(filtered.filter(d => d.creator))
  }


  useEffect(() => {
    removeIncompatibleDash()
  }, [selectedDevices, uneditableDash]);


  const deleteDashboard = async (dashId: string) => {
    const url = `api/dashboard/${dashId}`;
    await deleteWithAuth(url)
    setFilteredDashboards(prev => prev.filter(d => d._id !== dashId))
  }

  const handleDashboardCreation = async (newDashboard: IDashboard) => {
    if (isLoading) return
    setIsloading(true);
    if (newDashboard) {
      let tmpSelectedInstallation = await deepCopy(selectedInstallation);
      const tmpDash = await handleCopyDash(await deepCopy(newDashboard))
      tmpSelectedInstallation.configuration.dashboards.push(tmpDash);
      SET.installation(tmpSelectedInstallation);
    }
    setIsloading(false);
  };

  const tableHeadStyle = { "& .MuiTableCell-root": { textAlign: "center", bgcolor: "var(--primary_background-color)", color: "white" } }
  const tableBodyStyle = {
    backgroundColor: "var(--secondary_background-color)",
    "& .MuiTableRow-root": { ":hover": { bgcolor: "var(--selected-color)" }, cursor: isLoading ? 'disabled' : "pointer" },
    "& .MuiTableCell-root": { color: "white", textAlign: "center" }
  }

  return (
    <Grid container justifyContent="center" sx={{ width: '100%', maxHeight: '65%', overflow: 'auto', padding: 2 }}>
      <Grid item xs={12} width={'100%'}>
        <TextField
          sx={{ marginTop: 2 }}
          className="searchInput"
          label="Dashboard Name"
          type="text"
          fullWidth
          onChange={(e) => handleSearch(e)}
        />
        <TableContainer sx={{ background: '#222', marginTop: 2, borderRadius: 1 }}>
          <Table stickyHeader>
            <TableHead sx={tableHeadStyle}>
              <TableRow>
                <TableCell>Compatible Dashboards</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={tableBodyStyle}>
              {filteredDashboards.length > 0 &&
                filteredDashboards.map(dash => (
                  <TableRow key={dash._id} >
                    <TableCell sx={{ display: 'flex' }}>
                      <Button onDoubleClick={async () => handleDashboardCreation(dash)} color="info" sx={{ width: '95%', height: '100%' }}>
                        <TooltipOverflow
                          tooltipChildren={
                            <Typography
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              overflow="hidden"
                            >
                              {dash.name}
                            </Typography>
                          }
                        />
                      </Button>
                      <Button color="info" onClick={() => deleteDashboard(dash._id)}><DeleteForeverIcon /></Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

