import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
} from "@mui/material";
import { useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import StepZero from "./StepZero";
import ReactLoading from "react-loading";
import { enqueueSnackbar } from "notistack";
import { IService, IRestService, EServiceType, IComponent } from "../../../../common-interfaces/interfaces";
import { wizardBrands, deepCopy, Print, PrintError } from "../../../../helpers/utils";
import { useApiWrapper } from "../../../../hooks/useApiWrapper";
import useMount from "../../../../hooks/useMount";
import { useOnGet } from "../../../../hooks/useOnGet";
import { useOnSet } from "../../../../hooks/useOnSet";
import { restServiceAtom } from "../../../../recoil/Atom";
import { newServiceAtom, errAtom } from "../../../../recoil/CreateServiceAtoms";
import { ErrorAutoHide } from "../../../../config/general.config";

export default function Wizard(props: any) {
  const [newService, setNewService] = useRecoilState<IService>(newServiceAtom);
  const [restService, setRestService] =
    useRecoilState<IRestService>(restServiceAtom);
  const [allServices, setAllServices] = useState<IService[]>();
  const [activeStep, setActiveStep] = useState(0);
  const [err, setErr] = useRecoilState(errAtom);

  const resetService = useResetRecoilState(newServiceAtom);
  const restRest = useResetRecoilState(restServiceAtom);
  const restErr = useResetRecoilState(errAtom);

  const resetAtoms = () => {
    resetService();
    restRest();
    restErr();
  };

  const GET = useOnGet();
  const SET = useOnSet();
  const apiWrapper = useApiWrapper();

  const getServives = async () => {
    const url = `api/service?editable=false`;
    try {
      const services = await apiWrapper.getWithAuth(url, {});
      const uneditableServices = services.data.filter((s: IService) => wizardBrands.includes(s.brand ?? ''))
      setAllServices(uneditableServices);
    }catch(e){
      PrintError([e])
    }
  };

  const handleInit = () => {
    resetAtoms();
    if (props.service) {
      setNewService(props.service);
      setRestService(props.service.restService);
      setAllServices([props.service]);
      setActiveStep(1);
    } else {
      getServives();
    }
    setErr(false);
  };

  useMount(handleInit);
  const steps = [
    {
      label: "CreateService",
      description:
        allServices?.length === 1
          ? `Currently Editing: `
          : `Select an existing service:`,
      content: <StepZero allServices={allServices}></StepZero>,
    },
    // {
    //   label: "Common Configuration",
    //   description: `test`,
    //   content: <StepOne></StepOne>,
    // },
    // {
    //   label: "RequestState",
    //   description: "edit.",
    //   content: <StepTwoHandler></StepTwoHandler>,
    // },
    // {
    //   label: "RequestStateOptions",
    //   description: "edit.",
    //   content: <StepThreeHandler></StepThreeHandler>,
    // },
    // {
    //   label: "RequestSet",
    //   description: `set`,
    //   content: <StepFourHandler></StepFourHandler>,
    // },
    // {
    //   label: "RequestSetOptions",
    //   description: `set`,
    //   content: <StepFiveHandler></StepFiveHandler>,
    // },
  ];
  const step1 = [newService.protocol, newService.name];
  const step2 = () => {
    Print([newService, restService]);
    switch (newService.protocol) {
      case EServiceType.Rest:
        if (!restService) {
          return undefined;
        }
        return [
          restService.requestState.endpoint,
          restService.requestState.method,
          restService.requestState.response.languageType,
          restService.requestState.response.fieldToReturn,
          restService.requestState.response.type,
        ];
      default:
        return undefined;
    }
  };
  const step4 = () => {
    switch (newService.protocol) {
      case EServiceType.Rest:
        if (!restService) return undefined;
        return [
          restService.requestSet.endpoint,
          restService.requestSet.method,
          restService.requestSet.response.languageType,
          restService.requestSet.response.fieldToReturn,
          restService.requestSet.response.type,
        ];
      default:
        return undefined;
    }
  };
  const createRestService = async () => {
    let tmpComp: IComponent = await deepCopy(props.comp);
    let tmpService: IService = await deepCopy(newService);
    if (!tmpService.editable) {
      tmpService.editable = true;
    }
    tmpService.restService = await deepCopy(restService);
    if (tmpService._id === "newService") {
      //todo capire perché
      tmpService._id = GET.id();
      tmpComp.services
        ? tmpComp.services.push(tmpService)
        : (tmpComp.services = [tmpService]);
      Print(["newSERVICE", newService]);
    } else {
      if (!tmpComp.services) {
        return enqueueSnackbar("there was an error creating service", {
          variant: "error",
          autoHideDuration: ErrorAutoHide
        });
      }
      tmpComp.services = tmpComp.services.map((c) => {
        Print([c._id, newService._id]);
        c._id === newService._id
          ? Print(["return -->", tmpService.name])
          : Print(["return -->", c.name]);
        return c._id === newService._id ? tmpService : c;
      });
    }
    Print([tmpComp]);
    SET.comp(tmpComp);
  };

  const handleCreateService = async () => {
    switch (newService.protocol) {
      case EServiceType.Rest:
        await createRestService();
        break;

      default:
        break;
    }
  };

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(1);
        break;
      case 1:
        if (step1.every((x) => x)) {
          setErr(false);
          setActiveStep(2);
        } else {
          setErr(true);
        }
        break;
      case 2:
        let tmpStep2 = step2();
        if (tmpStep2 === undefined) return setErr(true);
        if (tmpStep2.every((x) => x)) {
          setErr(false);
          setActiveStep(3);
        } else setErr(true);
        break;
      case 3:
        setActiveStep(4);
        break;
      case 4:
        let tmpStep4 = step4();
        if (tmpStep4 === undefined) return setErr(true);
        if (tmpStep4.every((x) => x)) {
          setErr(false);
          setActiveStep(5);
        } else setErr(true);
        break;
      case 5:
        setErr(false);
        handleCreateService();
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  if (!allServices) {
    return (
      <Box className="center" height="650px">
        <ReactLoading type={"bars"} color={"#fff"} width={"60px"} />
      </Box>
    );
  }
  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box>{step.content}</Box>
              <Box
                sx={{
                  mt: index === 0 ? 2 : 4,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: index === 0 ? "column" : "row",
                    width: "100%",
                  }}
                >
                  {index === 0 ? (
                    <>
                      <Button
                        disabled={newService.editable}
                        variant="contained"
                        onClick={handleCreateService}
                        sx={{ mt: 1, ml: "auto", mr: 1, width: "30%" }}
                      >
                        Fast Create
                      </Button>
                      <Divider
                        sx={{
                          mt: 2,
                          mb: 1,
                          borderColor: "#666 !important",
                          width: "95%",
                          mx: "auto",
                        }}
                      ></Divider>
                    </>
                  ) : (
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1, width: "30%", ml: "auto" }}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1, width: index === 0 ? "100%" : "30%" }}
                  >
                    {index === steps.length - 1
                      ? "Finish"
                      : index !== 0
                        ? "Continue"
                        : props.service || !newService.editable
                          ? "Edit"
                          : "Create New"}
                  </Button>
                </Box> */}
                <Button
                  disabled={newService.editable}
                  variant="contained"
                  onClick={handleCreateService}
                  sx={{ mt: 1, mx: "auto", width: "80%" }}
                >
                  Create
                </Button>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
