import React, { useState, useCallback, useEffect } from "react";
import { Card, CardHeader, CardContent, IconButton, Typography, TextField, Box, Button, ButtonGroup, CardActions } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import EditIcon from "@mui/icons-material/Edit";
import { MuiColorInput } from "mui-color-input";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { TinyColor } from "@ctrl/tinycolor";
import { selectedDashboardAtom, selectedInstallationAtom } from "../../../../recoil/atoms";
import { deepCopy } from "../../../../helpers/utils";
import Loading_overlay from "./Loading_overlay";
import CopyDashboardButton from "./CopyDashboardButton";
import ShareButton from "../../../../common-components/common/ShareButton";
import { ERequestSubtype, IDashboard, IInstallation } from "../../../../common-interfaces/interfaces";
import { useOnSet } from "../../../../hooks/useOnSet";
import "../../../../styles/DashboardPreview.css";
import DashboardPreviewImage from "./DashboardPreviewImage";

interface DashboardPreviewProps {
  dashboard: IDashboard;
  handleDeleteDashboard: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void;
}

const DashboardPreview: React.FC<DashboardPreviewProps> = ({ dashboard, handleDeleteDashboard }) => {
  const [isLoading, setisLoading] = useState(false);
  const [dash, setDash] = useState<IDashboard>(dashboard);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: dash._id });
  const style = { transform: CSS.Transform.toString(transform), transition };

  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);

  useEffect(() => {
    setDash(dashboard)
  }, [dashboard])

  const navigate = useNavigate();
  const SET = useOnSet();
  const { installationId } = useParams<{ installationId: string }>();
  const handleResetDash = useResetRecoilState(selectedDashboardAtom);

  const handleEditDashboard = useCallback((_: React.MouseEvent<HTMLButtonElement>, id: string) => {
    handleResetDash();
    navigate(`/installation/${installationId}/dashboard/${id}`);
  }, [navigate, handleResetDash, installationId]);


  const handleInputChange = (key: 'name' | 'description' | 'backgroundColor', value: string) => {
    setDash(prev => ({ ...prev, [key]: value }));
  }

  const handleColorChange = (selectedColor: string) => {
    const color = new TinyColor(selectedColor).toHex8String();
    handleInputChange('backgroundColor', color)
  };

  const handleInputBlur = async () => {
    const tmpSelectedInstallation: IInstallation = await deepCopy(selectedInstallation);
    tmpSelectedInstallation.configuration.dashboards = tmpSelectedInstallation.configuration.dashboards.map(d => d._id === dash._id ? dash : d)
    SET.installation(tmpSelectedInstallation);
  }


  return (
    <Card
      sx={{
        maxHeight: "500px",
        minHeight: "500px",
        background: "#333",
        border: "1px solid #484848",
        zIndex: isDragging ? 2 : 1,
        opacity: 1,
        cursor: isLoading ? "not-allowed" : "default",
      }}
      ref={setNodeRef}
      style={style}
      className="header"
    >
      {isLoading && <Loading_overlay />}
      <Button
        {...listeners}
        {...attributes}
        className="dragHandleBtn"
        disabled={isLoading}
        sx={{ m: 0, p: 0, borderRadius: 0 }}
      >
        <DragHandleIcon />
      </Button>
      <CardHeader
        action={
          <IconButton size="small" disabled={isLoading} onClick={(event) => handleDeleteDashboard(event, dash._id)}>
            <DeleteForeverIcon sx={{ color: "#ccc" }} />
          </IconButton>
        }
        title={
          <span className="dashboardTitleContainer">
            <TextField
              type="text"
              disabled={isLoading}
              variant="standard"
              value={dash.name}
              className="nameInput"
              sx={{ "& .MuiInput-input": { color: "#ccc" } }}
              fullWidth
              onFocus={(event) => { event.target.select(); }}
              onChange={(e) => handleInputChange("name", e.target.value)}
              onBlur={handleInputBlur}
            />
          </span>
        }
      />
      <DashboardPreviewImage loading={isLoading} setLoading={setisLoading} dashId={dash._id} />
      <CardContent
        sx={{
          width: "calc(100% - 32px)",
          padding: "16px",
          flexDirection: "column",
        }}
        className="center"
      >
        <Typography variant="body2" color="#ccc" paddingBottom="10px" mr="auto">
          Description:
        </Typography>
        <TextField
          multiline
          disabled={isLoading}
          variant="filled"
          className="description scrollable"
          value={dash.description}
          onFocus={(event) => { event.target.select(); }}
          sx={{
            pr: '4px',
            "& .MuiFilledInput-root": {
              color: "#ccc",
              width: "100%",
              border: "var(--primary_border)",
            },
          }}
          onChange={(e) => handleInputChange("description", e.target.value)}
          onBlur={handleInputBlur}
        />
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Box display="flex" alignItems="center">
          <MuiColorInput
            disabled={isLoading}
            value={dash.backgroundColor ?? '#333'}
            format={"hex"}
            variant="standard"
            sx={{
              px: '5%',
              '& .MuiInput-root': {
                color: 'white',    
                fontSize: 13            
              }
            }} 
            onChange={handleColorChange}
            PopoverProps={{ onClose: handleInputBlur }}
            className="colorInput K-ARRAY-COLOR_PICKER"
          />
        </Box>
        <ButtonGroup variant="contained">
          <CopyDashboardButton id={dash._id} />
          <ShareButton argument={ERequestSubtype.DASHBOARD} body={dash} bgColor={'apple'}/>
          <Button
            size="small"
            color="apple"
            variant="contained"
            title="Edit Dashboard"
            disabled={isLoading}
            onClick={(event) => handleEditDashboard(event, dash._id)}
          >
            <EditIcon sx={{ color: '#ccc' }} />
          </Button>
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default DashboardPreview;
