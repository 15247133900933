import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { FC, useCallback } from "react"
import { IComponent, IInstallation } from "../../../common-interfaces/interfaces"
import { deepCopy, PrintError } from "../../../helpers/utils"
import { useOnSet } from "../../../hooks/useOnSet"
import { useRecoilState } from "recoil"
import { selectedInstallationAtom } from "../../../recoil/atoms"

const formControlStyle = { minWidth: '200px', "& .MuiFormLabel-root, .Mui-focused, .MuiInputBase-root": { color: "var(--light_grey-color) !important" } }
const SelectStyle = { "& .MuiSvgIcon-root": { color: "var(--light_grey-color) !important" } }

const EditMultiServiceHosts: FC<{ component: IComponent }> = ({ component }) => {
    const SET = useOnSet()
    const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);

    const servicesHaveTheSameHost = useCallback((): string | undefined => {
        if (!component.services)
            return
        let host: string = component.services[0].host
        return component.services?.every(service => service.host === host) ? host : undefined
    }, [component])

    const handleChangeHost = async (e: any) => {
        let value = e.target.value
        let tmpComp: IComponent = await deepCopy(component);
        tmpComp.services?.forEach(s => s.host = value)
        try {
            await SET.comp(tmpComp);
        } catch (err) {
            PrintError([err])
        }
    };

    if (!component.services || component.services?.length === 1 ||  !selectedInstallation.devices || selectedInstallation.devices?.length === 0)
        return <></>
    return (
        <FormControl variant="standard" sx={formControlStyle}>
            <InputLabel>Host</InputLabel>
            <Select onChange={handleChangeHost} value={servicesHaveTheSameHost()} sx={SelectStyle}>
                {selectedInstallation?.devices?.map(device =>
                    <MenuItem key={device._id} value={device.local_ip}>
                        {device.hostname}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )

}

export default EditMultiServiceHosts