import { Button, Grid, Paper } from "@mui/material";
import { IDashboard, IInstallation } from "../../../common-interfaces/interfaces";
import "../../../styles/CentralBox.css";
import DashboardPreview from "./dashboardPreview/DashboardPreview";
import AddIcon from "@mui/icons-material/Add";
import { useRecoilState } from "recoil";
import { isLoadingAtom, selectedInstallationAtom } from "../../../recoil/atoms";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { deepCopy } from "../../../helpers/utils";
import Loading from "react-loading";
import { useOnGet } from "../../../hooks/useOnGet";
import { useOnSet } from "../../../hooks/useOnSet";
import CentralActions from "./CentralActions";

const CentralContainer = (props: any) => {
  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [isLoading, setIsloading] = useRecoilState<boolean>(isLoadingAtom);
  const GET = useOnGet();
  const SET = useOnSet();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDeleteDashboard = async (event: any, id: any) => {
    let tempDashs = selectedInstallation.configuration.dashboards.filter(
      (d) => d._id !== id
    );
    let tmpSelectedInstallation = await deepCopy(selectedInstallation);
    tmpSelectedInstallation.configuration.dashboards = tempDashs;
    SET.installation(tmpSelectedInstallation);
  };
  
  const generateDash = () => {
    //dashboard creation
    const newDashboard = {
      _id: GET.id(),
      name: "Insert Dashboard Name",
      description: "Insert Dashboard Description",
      backgroundColor: "#282828",
      components: [],
    };
    return newDashboard;
  };

  const handleDashboardCreation = async () => {
    setIsloading(true);
    const newDashboard = generateDash();
    if (newDashboard) {
      let tmpSelectedInstallation = await deepCopy(selectedInstallation);
      tmpSelectedInstallation.configuration.dashboards.push(newDashboard);
      SET.installation(tmpSelectedInstallation);
    }
    setIsloading(false);
  };


  async function handleDragEnd(event: any) {
    const { active, over } = event;
    if (!over) return
    if (active.id !== over?.id) {
      let tmpSelectedInstallation: IInstallation = await deepCopy(
        selectedInstallation
      );
      let dashboards = tmpSelectedInstallation.configuration.dashboards;
      if (!dashboards) return;

      const oldIndex = dashboards.findIndex(
        (dashboard: IDashboard) => dashboard._id === active.id
      );
      const newIndex = dashboards.findIndex(
        (dashboard: IDashboard) => dashboard._id === over.id
      );
      if (oldIndex !== newIndex) {
        //graphical move before api put call
        dashboards = arrayMove(dashboards, oldIndex, newIndex);
        tmpSelectedInstallation.configuration.dashboards = dashboards;
        SET.installation(tmpSelectedInstallation);
      }
    }
  }

  if (isLoading) return <Loading></Loading>;

  return (
    <Paper elevation={0} className="dashboardsWrapper">
      <CentralActions handleDashboardCreation={handleDashboardCreation} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={props.dashboards.map((dashboard: IDashboard) => dashboard._id)}
          strategy={rectSortingStrategy}
        >
          <Grid container className="dashboardsContainer">
            {props.dashboards.map((dashboard: IDashboard) => (
              <Grid
                key={dashboard._id}
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={3}
                className="dashboard"
              >
                <DashboardPreview
                  key={dashboard._id}
                  dashboard={dashboard}
                  handleDeleteDashboard={handleDeleteDashboard}
                />
              </Grid>
            ))}
            <Grid
              key={"add"}
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={3}
              sx={{ my: "auto", }}
            >
              <Button
                className="addContainer"
                sx={{
                  maxWidth: 311,
                  minWidth: 312,
                  minHeight: "520px",
                  pb: 1,
                  ml: '20px',
                  background: props.bg,
                }}
                onClick={async () => await handleDashboardCreation()}
              >
                <AddIcon className="add" />
              </Button>
            </Grid>
          </Grid>
        </SortableContext>
      </DndContext>
    </Paper>
  );
};
export default CentralContainer;
