import { Breadcrumbs, Typography, Link } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import { IInstallation } from '../../common-interfaces/interfaces';
import { selectedComponentIDsAtom, selectedDashboardAtom, selectedInstallationAtom } from '../../recoil/atoms';
import { useOnGet } from '../../hooks/useOnGet';
import { currentSelectedComponentAtom } from '../../recoil/Atom';

const BC = () => {
  const navigate = useNavigate();
  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);
  const [currentSelectedComponent, setCurrentSelectedComponent] = useRecoilState(currentSelectedComponentAtom)
  const GET = useOnGet()
  const { installationId } = useParams();
  const { componentId } = useParams();
  const { dashboardId } = useParams();



  const handleNavigationToInstallation = () => {
    navigate(`/installation/${installationId}`);
  };
  const handleNavigateToDash = () => {
    navigate(`/installation/${installationId}/dashboard/${dashboardId}`);
  };

  return (
    <Breadcrumbs
      separator=">"
      sx={{
        pl: 2,
        background: "#212121",
        height: "var(--breadcrumb-height)",
        display: "flex",
        alignItems: "center",
      }}
      color="var(--light_grey-color)"
    >
      <Link
        underline="hover"
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        color="inherit"
        onClick={() => navigate(`/installations`)}
      >
        Installations
      </Link>
      <Link
        underline="hover"
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        color="inherit"
        onClick={handleNavigationToInstallation}
      >
        {selectedInstallation?.name}
      </Link>
      {componentId ? <Link
        underline="hover"
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        color="inherit"
        onClick={handleNavigateToDash}
      >
        {selectedDashboard?.name ?? "noDash"}
      </Link> :
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="secondary"
        >
          {selectedDashboard?.name ?? "noDash"}
        </Typography>
      }
      {componentId && <Typography
        sx={{ display: "flex", alignItems: "center" }}
        color="secondary"
      >
        {currentSelectedComponent?.name ?? "noDash"}
      </Typography>}
    </Breadcrumbs>
  )
}
export default BC