import { CSSProperties, useEffect, useRef, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  GRIDCOL_Atom,
  GRIDROW_Atom,
  busyCellsAtom,
  cellDimensionAtom,
  isSelectingAtom,
  selectedContainerAtom,
} from "../../../recoil/workspace";
import SquareRender from "./SquareRender";
import { IDragAndSelectItem } from "../../../local-interfaces/local-interfaces";
import {
  Box,
  boxesIntersect,
  useSelectionContainer,
} from "@air/react-drag-to-select";
import { Grid } from "@mui/material";
import {
  selectedComponentIDsAtom,
  selectedDashboardAtom,
  workspaceWidthAtom,
} from "../../../recoil/atoms";
import { useOnDND } from "../../../hooks/useOnDND";
import { Print, PrintError, filterUniqueFromArray } from "../../../helpers/utils";

export default function Workspace() {
  const containerRef = useRef<any>(null);
  const gridContainer = useRef<any>(null);
  const [workspaceWidth] = useRecoilState(workspaceWidthAtom);
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [_, setCellDimension] = useRecoilState(cellDimensionAtom);
  const [selectedContainer] = useRecoilState(selectedContainerAtom);
  const [resizing, setResizing] = useState(false);
  const [GRIDCOL] = useRecoilState(GRIDCOL_Atom);
  const [GRIDROW] = useRecoilState(GRIDROW_Atom);
  const DND = useOnDND();
  const resetBusyCell = useResetRecoilState(busyCellsAtom);

  useEffect(() => {
    if (!selectedDashboard?.components) {
      setSelectableItems([]);
      resetBusyCell();
    }
    populateSelectableItem();
    DND.getAllBusyCells();
  }, [selectedDashboard, selectedContainer]);

  useEffect(() => {
    const handleResize = (ev?: any) => {
      const { current } = containerRef;
      if (current) {
        const workspaceDimens = current.getBoundingClientRect();
        let width = Math.floor(workspaceDimens.width / GRIDCOL);
        let height = Math.floor(workspaceDimens.width / GRIDCOL);
        width = width > 150 ? 150 : width;
        height = height > 150 ? 150 : height;
        setCellDimension({ width, height });
      }
      setResizing(false);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [workspaceWidth, GRIDCOL, GRIDROW]);

  /************************ Drag-to-Select ************************/
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isSelecting, setIsSelecting] = useRecoilState(isSelectingAtom);
  const [__, setSelectedComponentIDs] = useRecoilState<string[]>(
    selectedComponentIDsAtom
  );
  const [selectableItems, setSelectableItems] = useState<IDragAndSelectItem[]>(
    []
  );
  const selectSquareStyle: CSSProperties = {
    border: "2px dashed var(--secondary-color)",
    borderRadius: 4,
    opacity: 0.5,
    zIndex: 5,
  };
  //https://github.com/AirLabsTeam/react-drag-to-select

  // https://codesandbox.io/s/billowing-lake-rzhid4?file=/src/App.tsx:307-322
  const { DragSelection } = useSelectionContainer({
    eventsElement: document.getElementById("root"),
    shouldStartSelecting: (target) => {
      if (target instanceof HTMLElement) {
        //WORKAROUND PER IMPEDIRE IL drag-to-select NEL drag-and-drop
        let isSelectableArea = target.className.split(" ").includes("square");
        if (isSelectableArea) {
        }
        return isSelectableArea;
      }
      return false;
    },
    onSelectionChange: (box) => {
      setIsSelecting(true);
      /**
       * Here we make sure to adjust the box's left and top with the scroll position of the window
       * @see https://github.com/AirLabsTeam/react-drag-to-select/#scrolling
       */
      const scrollAwareBox: Box = {
        ...box,
        top:
          box.top +
          window.scrollY +
          (selectedContainer ? selectedContainer.top : 0),
        left:
          box.left +
          window.scrollX +
          (selectedContainer ? selectedContainer.left : 0),
      };
      const idsToSelect: string[] = [];
      selectableItems.forEach((item) => {
        if (boxesIntersect(scrollAwareBox, item.current)) {
          idsToSelect.push(item.id);
        }
      });
      setSelectedIds(filterUniqueFromArray(idsToSelect));
      const timeout = setTimeout(() => {
        //workaround per il click on workspace
        setIsSelecting(false);
        clearTimeout(timeout)
      }, 1000)
    },
    onSelectionEnd: () => {
      setSelectedComponentIDs(selectedIds);
    },

    selectionProps: {
      style: selectSquareStyle,
    },
    isEnabled: true,
  });

  const populateSelectableItem = () => {
    if (!gridContainer.current) {
      PrintError(["no gridROW , gridContainer.current = " , gridContainer.current]);
       return
    }

    const tmpItems: IDragAndSelectItem[] = [];
    Array.from(gridContainer.current.children).map((rows: any) => {
      Array.from(rows.children).map((item: any) => {
        const col = parseInt(item.children[0].id.split("_")[0], 10);
        const row = parseInt(item.children[0].id.split("_")[1], 10);
        const allBusyCells = DND.getAllBusyCells();
        const cellBusy = allBusyCells?.find(
          (cell) => cell.x === col && cell.y === row
        );

        if (cellBusy?.busyByID) {
          let newItem: IDragAndSelectItem = {
            id: cellBusy.busyByID,
            current: item.getBoundingClientRect(),
          };
          tmpItems.push(newItem);
        }
      });
    });
    Print(["selectable items ..>", tmpItems]);
    setSelectableItems(tmpItems);
  };
  /************************ END OF Drag-to-Selct ************************/
  return (
    <>
      {!resizing && (
        <Grid
          ref={containerRef}
          sx={{
            width: workspaceWidth,
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            marginX: "auto",
            flexShrink: 0,
          }}
        >
          <Grid
            sx={{ maxHeight: "100%", overflow: 'auto', p: "3px", pb: "50px" }}
            ref={gridContainer}
            onScroll={populateSelectableItem}
          >
            {Array.from({ length: GRIDROW }, (_, row) => (
              <Grid container key={row}>
                {Array.from({ length: GRIDCOL }, (_, col) => (
                  <Grid item xs={12 / GRIDCOL} key={col}>
                    <Grid item xs={12} id={`${col}_${row}`}>
                      <SquareRender
                        key={col + row}
                        x={col}
                        y={row}
                      ></SquareRender>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
          <DragSelection />
        </Grid>
      )}
    </>
  );
}
