import { Grid, IconButton, TextField, Typography } from "@mui/material"
import { ERequestSubtype, EServiceType, IService } from "../../../common-interfaces/interfaces"
import { FC, useEffect, useState } from "react"
import { useRecoilState, useResetRecoilState } from "recoil"
import { currentSelectedComponentAtom, selectedServiceAtom, uneditableServiceAtom } from "../../../recoil/Atom"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import HandleHost from "./HandleHost"
import { Save } from "@mui/icons-material"
import { Print, deepCopy } from "../../../helpers/utils"
import { useOnSet } from "../../../hooks/useOnSet"
import { useApiWrapper } from "../../../hooks/useApiWrapper"
import { enqueueSnackbar } from "notistack"
import { useOnGet } from "../../../hooks/useOnGet"
import { oscColor, restColor } from "../leftBar/servicesColor"
import SelectServiceParams from "../../../common-components/editComponents/editServices/SelectServiceParams"
import ShareButton from "../../../common-components/common/ShareButton"
import { ErrorAutoHide, SuccessAutoHide } from "../../../config/general.config"

const ServiceBox: FC<{ service: IService }> = ({ service }) => {
  const [currentService, setCurrentService] = useState<IService>(service)
  const [comp, setComp] = useRecoilState(currentSelectedComponentAtom)
  const [__, setSelectedService] = useRecoilState<IService>(selectedServiceAtom)
  const [_, setAllServices] = useRecoilState<IService[]>(uneditableServiceAtom)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
  const SET = useOnSet()
  const GET = useOnGet()

  const { getWithAuth, postWithAuth } = useApiWrapper()
  const resetSelectedService = useResetRecoilState(selectedServiceAtom)

  useEffect(() => {
    setCurrentService(service)
  }, [service]);

  const handleClickOnService = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    Print(["%c > Selected service => :", "background: #000; color: #ff0", currentService]);
    setSelectedService({ ...currentService })
  }

  const handleChangeName = (newValue: string) => {
    const tmpService: IService = { ...currentService, name: newValue }
    setCurrentService(tmpService)
  }

  const handleBlurName = async (value: string) => {
    const tmpService = await deepCopy(currentService)
    tmpService.name = value
    setSelectedService(tmpService)
    SET.service(tmpService, comp._id)
  }

  const fetchAllService = async () => {
    const url = `api/service?editable=false`;
    const services = await getWithAuth(url, {});
    return services.data;
  };

  const handleSaveService = async () => {
    setIsSaveButtonDisabled(true)
    enqueueSnackbar('now saving...', { variant: 'info', autoHideDuration: SuccessAutoHide })
    const allServices = await fetchAllService()
    if (allServices?.find((s: IService) => s.name === currentService.name)) {
      setIsSaveButtonDisabled(false)
      return enqueueSnackbar("A service with that name already exists", {
        variant: "error",
        autoHideDuration: ErrorAutoHide
      });
    }
    let tempService: IService = await deepCopy(currentService);
    tempService.origin = tempService._id;
    tempService._id = GET.id();
    tempService.editable = false;
    const url = `api/service`;
    try {
      await postWithAuth(url, tempService);
      enqueueSnackbar('saved correctly', { variant: 'info', autoHideDuration: SuccessAutoHide })
      setAllServices([...allServices, tempService])
    } finally {
      setIsSaveButtonDisabled(false)
    }
  };

  const handleDelete = async () => {
    let tmpComp = await deepCopy(comp);
    if (!tmpComp) return;
    let tmpServices = tmpComp.services?.filter(
      (s: IService) => s._id !== currentService._id
    );
    tmpComp.services = tmpServices;
    SET.comp(tmpComp);
    resetSelectedService()
    setComp(tmpComp)
    enqueueSnackbar('deleted correctly', { variant: 'info', autoHideDuration: SuccessAutoHide })
  };

  return (
    <Grid
      height={'95%'}
      position={'relative'}
      sx={{
        background: service.protocol === EServiceType.Rest ? restColor : oscColor, cursor: 'pointer', '&:hover': {
          bgcolor: '#212121'
        }
      }}
      mx={'auto'}
      borderRadius={1}
      xs={11.5}
      onClick={(ev) => handleClickOnService(ev)}
      container
      item
      direction={'column'}
    >
      {/* SERVICE HEADER */}
      <Grid
        item
        xs={2}
        bgcolor={'#ccc'}
        width={'100%'}
        justifyContent={'space-between'}
        borderRadius={1}
      >
        <TextField
          onChange={(ev) => handleChangeName(ev.target.value)}
          onBlur={(ev) => handleBlurName(ev.target.value)}
          onKeyDown={(ev) => ev.key === 'Enter' && handleBlurName(currentService.name)}
          value={currentService.name}
          fullWidth
          sx={{
            fontSize: '10px',
            textAlign: 'center',
            '& input': {
              textAlign: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            },
          }}
        />
      </Grid>
      <Grid container item flexGrow={1}>
        <Grid container justifyContent={'space-around'} alignItems={'center'}>
          <Grid item xs={2}>
            <Typography color="#ccc" pl={1} height={'100%'} width={'100%'}>
              Host:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <HandleHost service={service} />
          </Grid>
        </Grid>
        <Grid container justifyContent={'space-around'} alignItems={'center'}>
          <Grid item xs={11}>
            <SelectServiceParams label={'Params: '} service={service} component={comp} />
          </Grid>
        </Grid>
      </Grid>
      {/* duration */}
      <Grid marginTop={'auto'} container item xs={1} justifyContent={'flex-end'}>
        <IconButton sx={{ padding: 0.5 }} onClick={handleSaveService} disabled={isSaveButtonDisabled}>
          <Save className={isSaveButtonDisabled ? '' : "whiteIcon"} />
        </IconButton>
        <IconButton sx={{ padding: 0.5 }} onClick={handleDelete}>
          <DeleteForeverIcon className="whiteIcon" />
        </IconButton>
        <ShareButton argument={ERequestSubtype.SERVICE} body={currentService} bgColor={'primary'} styleProps={{ minWidth: 35, backgroundColor: 'transparent' }} />
      </Grid>
      <Typography position={'absolute'} bottom={1} left={5} fontSize={10} letterSpacing={1} fontWeight={400} textTransform={'uppercase'}>{service.protocol}</Typography>
    </Grid >
  );

}

export default ServiceBox