import "./App.css";
import Login from "./screens/Login/Login";
import Configuration from "./screens/Configuration/Configuration";
import Installations from "./screens/Installations/Installations";
import { theme } from "./theme";
import { ThemeProvider } from "@emotion/react";
import { Routes, Route } from "react-router-dom";
import EditDashboard from "./screens/Dashboard/EditDashboard";
import { useRecoilState } from "recoil";
import { loggedUserAtom } from "./recoil/atoms";
import { ILoggedUser } from "./local-interfaces/local-interfaces";
import { useOnShortcuts } from "./hooks/useOnShortcuts";
import { SnackbarOrigin, SnackbarProvider } from "notistack";
import PhonePreview from "./screens/PhonePreview/PhonePreview";
import EditComponent from "./screens/Component/EditComponent";
import DocsScreen from "./screens/Docs/DocsScreen";
import FromCloud from "./screens/FromCloud/FromCloud";
import Notifications from "./screens/Requests/Requests";
import AdminToolScreen from "./screens/AdminTool/AdminToolScreen";

const snackbarStyle: SnackbarOrigin = { horizontal: "right", vertical: "bottom" }

const App = () => {
  useOnShortcuts();

  const [loggedUser, _] = useRecoilState<ILoggedUser | undefined>(loggedUserAtom);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5} anchorOrigin={snackbarStyle}>
        {loggedUser ?
          <div className="App">
            <Routes>
              {/* <Route path="*" element={<div>error 404<br></br>page not found</div>} /> */}
              <Route
                path="/"
                element={!loggedUser ? <Login /> : <Installations />}
              />
              <Route
                path="/installations"
                element={<Installations />}
              />
              <Route
                path="/installation/:installationId"
                element={<Configuration />}
              />
              <Route
                path="/installation/:installationId/dashboard/:dashboardId"
                element={<EditDashboard />}
              />
              <Route
                path="/installation/:installationId/dashboard/:dashboardId/component/:componentId"
                element={<EditComponent />}
              />
              <Route
                path="/preview/:dashboardId"
                element={<PhonePreview />}
              />
              <Route
                path="/docs/:nodeId/:docsSearchTerm"
                element={<DocsScreen />}
              />
              <Route
                path="/plantFromCloud/:plantId"
                element={<FromCloud />}
              />
              <Route
                path="/requests"
                element={<Notifications />}
              />
              <Route
                path="/adminTool"
                element={<AdminToolScreen />}
              />
            </Routes>
          </div> :
          <div className="App">
            <Routes>
              {/* <Route path="*" element={<div>error 404<br></br>page not found</div>} /> */}
              <Route
                path="*"
                element={<Login />}
              />
              <Route
                // path="/fromCloud/:token/:plantId"
                path="/plantFromCloud/:plantId"
                element={<FromCloud />}
              />
            </Routes>
          </div>
        }
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
