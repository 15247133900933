import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loggedUserAtom, selectedInstallationAtom } from "../../../recoil/atoms";
import { ILoggedUser } from "../../../local-interfaces/local-interfaces";
import { useState } from "react";
import { Button, Popover, Typography } from "@mui/material";
import img from "../../../assets/svg/header_white.svg";
import { useOnUser } from "../../../hooks/useOnUser";
import CreatePartnership from "./CreatePartnership";
import HandleEditInstallation from "../../../screens/Installations/modifyInstallation/HandleEditInstallation";
import NotificationButton from "../../../screens/Requests/common/NotificationButton";
import useRequestNotification from "../../../screens/Requests/hooks/useRequestNotification";

const logoButtonStyle = { height: "var(--header-heigth)" }
// const docsButtonStyle = { borderRight: 'var(--primary_border)', borderRadius: '0', height: "var(--header-heigth)", color: 'inherit' }
const accountButtonStyle = { height: "var(--header-heigth)" }
const popoverStyle = { transform: "translateX(1vw)" }
const logoutButtonStyle = { minWidth: "200px", maxWidth: "200px", maxHeight: "25px", display: "flex", flexDirection: "row", border: "var(--primary_border)", borderTopLeftRadius: "0 !important", borderTopRightRadius: "0 !important" }
const exitIconStyle = { ml: "auto" }

const Header = () => {
  useRequestNotification()

  const [loggedUser] = useRecoilState<ILoggedUser | undefined>(loggedUserAtom);
  const [selectedInstallation] = useRecoilState(selectedInstallationAtom);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const currentRoute = useLocation()
  const onUser = useOnUser();

  const handleLogout = async () => {
    onUser.logout();
    navigate("/");
  };


  const logoHome = () => {
    navigate("/installations");
  };

  // const navigateDoc = () => {
  //   const openPreview = window.open("/docs/0/home");
  // };

  const handleProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <AppBar position="static" className="headerWrapper" elevation={0}>
      <Button sx={logoButtonStyle}>
        <img src={img} alt="K" onClick={logoHome} className="headerLogo" />
      </Button>
      <Box flexGrow={1} />
      {/* <Button
        size="large"
        onClick={navigateDoc}
        sx={docsButtonStyle}
      >
        <Typography>
          DOCS
        </Typography>
      </Button> */}
      <Button
        size="large"
        color="inherit"
        onClick={handleProfile}
        sx={accountButtonStyle}
      >
        <Typography marginRight={'20px'}>
          {loggedUser?.firstname} {loggedUser?.lastname}
        </Typography>
        <AccountCircleIcon />
      </Button>
      <NotificationButton />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={popoverStyle}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor="var(--primary-color)"
        >
          <CreatePartnership></CreatePartnership>
          {selectedInstallation && (
            <Button
              variant="contained"
              sx={{
                minWidth: "200px",
                maxWidth: "200px",
                maxHeight: "25px",
                display: "flex",
                flexDirection: "row",
                border: "var(--primary_border)",
                borderRadius: 0,
              }}
            >
              <HandleEditInstallation
                ml={"auto"}
                text={"EDIT CLIENT"}
                plantID={selectedInstallation.id_plant}
              ></HandleEditInstallation>
            </Button>
          )}
          <Button
            variant="contained"
            sx={logoutButtonStyle}
            onClick={handleLogout}
          >
            Logout
            <ExitToAppIcon fontSize={"small"} sx={exitIconStyle} />
          </Button>
        </Box>
      </Popover>
    </AppBar >
  );
}

export default Header