import { Apps, FilterList, North, Sort, South, TableRows } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Popover, PopoverOrigin, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { IPlantFull, IPlantUser } from "../../common-interfaces/interfaces";
import { compareStringsAsDates } from "../../helpers/utils";
import { IInstallationRow } from "../../local-interfaces/local-interfaces";
import { allPlantsFullAtom } from "../../recoil/atoms";
import CreateInstallationDialog from "./createInstallation/CreateInstallationDialog";
import InstallationsGrid from "./layout/InstallationsGrid";
import InstallationsTable from "./layout/InstallationsTable";

const searchBarStyle = { borderRadius: "5px", backgroundColor: "white", width: "50%" }
const checkBoxStyle = { color: 'white', "&.Mui-checked": { color: 'white', }, "&.Mui-disabled": { color: 'grey' } }
const formControlLabelStyle = { "& .MuiFormControlLabel-label.Mui-disabled": { color: 'grey' } }
const gridItemStyle = { cursor: "pointer" }
const popoverStyle = { "& .MuiPaper-root": { backgroundColor: 'var(--primary-color)' } }
const sortPopoverAnchorOrigin: PopoverOrigin | undefined = { vertical: 'bottom', horizontal: 'center' }
const sortPopoverTransformOrigin: PopoverOrigin | undefined = { vertical: 'top', horizontal: 'right' }
const filterPopoverAnchorOrigin: PopoverOrigin | undefined = { vertical: 'bottom', horizontal: 'center' }
const filterPopoverTransformOrigin: PopoverOrigin | undefined = { vertical: 'top', horizontal: 'left' }
const popoverStackStyle = { minWidth: '150px', boxSizing: 'border-box', padding: '10px', background: "var(--primary-color)", color: "white" }

enum Layout { Table, Cards }

interface ISortingInterface {
  name?: string,
  method: (a: IInstallationRow, b: IInstallationRow) => number,
  update?: () => void,
}

enum EFilteredKey {
  NAME = "installationName",
  CLIENTS = "clients",
  LOCATION = "location",
  LAST_EDITED = "lastEdit",
  CREATED = "creationDate"
}
interface filteringInterface {
  key: EFilteredKey,
  label: string,
  checked: boolean,
  disabled: boolean,
}

const InstallationBody = () => {

  const [allPlantsFull] = useRecoilState<IPlantFull[]>(allPlantsFullAtom);

  const [installationRows, setInstallationRows] = useState<IInstallationRow[]>([]);
  const [filteredRows, setFilteredRows] = useState<IInstallationRow[]>([]);
  const [layout, setLayout] = useState(Layout.Cards);
  const [sortingFunctionIndex, setSortingFunctionIndex] = useState<number>(0);
  const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null);
  const [sortAnchor, setSortAnchor] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    allPlantsFull && setInstallationRows(
      allPlantsFull?.reduce((acc: IInstallationRow[], pf: any) => {
        const plant = pf.plant
        if (plant && pf?.installationName)
          acc.push({
            location: [plant.address, plant.city, plant.area].join(", "),
            plant: plant,
            installationName: pf.installationName,
            clients: pf.clients.map((client: IPlantUser) => `${client?.user?.first_name} ${client?.user?.last_name}`),
            creationDate: pf.creationDate ? new Date(pf.creationDate * 1000).toLocaleDateString() : "",
            lastEdit: pf.lastEdit ? new Date(pf.lastEdit * 1000).toLocaleDateString() : ""
          })
        return acc
      }, []))
  }, [allPlantsFull]);

  useEffect(() => {
    if (!installationRows?.length)
      return;
    setFilteredRows([...installationRows.sort(sortingArray[sortingFunctionIndex].method)]);
  }, [installationRows, sortingFunctionIndex]);

  const sortingArray: ISortingInterface[] = [
    { name: "Name", method: (a: IInstallationRow, b: IInstallationRow) => ((a?.installationName ?? "").toLowerCase() >= (b?.installationName ?? "").toLowerCase() ? 1 : -1), update: () => updateSortingFunctionIndex(0) },
    { method: (a: IInstallationRow, b: IInstallationRow) => ((a?.installationName ?? "").toLowerCase() < (b?.installationName ?? "").toLowerCase() ? 1 : -1) },
    { name: "Last edited", method: (a: IInstallationRow, b: IInstallationRow) => compareStringsAsDates(a.lastEdit, b.lastEdit), update: () => updateSortingFunctionIndex(2) },
    { method: (a: IInstallationRow, b: IInstallationRow) => compareStringsAsDates(b.lastEdit, a.lastEdit)},
    { name: "Created", method: (a: IInstallationRow, b: IInstallationRow) => compareStringsAsDates(a.creationDate, b.creationDate), update: () => updateSortingFunctionIndex(4) },
    { method: (a: IInstallationRow, b: IInstallationRow) => compareStringsAsDates(b.creationDate, a.creationDate)},
  ]

  const filteringArray: filteringInterface[] = [
    { key: EFilteredKey.NAME, label: "Name", checked: true, disabled: true },
    { key: EFilteredKey.CLIENTS, label: "Clients", checked: false, disabled: false },
    { key: EFilteredKey.LOCATION, label: "Location", checked: false, disabled: false },
    { key: EFilteredKey.LAST_EDITED, label: "Last edited", checked: false, disabled: false },
    { key: EFilteredKey.CREATED, label: "Created", checked: false, disabled: false }
  ]

  const [refFilteringArray, setRefFilteringArray] = useState(filteringArray)

  const updateSortingFunctionIndex = (sortTypeIndex: number) => {
    const diff = sortingFunctionIndex - sortTypeIndex;
    if (diff === 0 || diff === 1) {
      if (sortingFunctionIndex % 2)
        setSortingFunctionIndex(sortingFunctionIndex - 1)
      else
        setSortingFunctionIndex(sortingFunctionIndex + 1)
    } else
      setSortingFunctionIndex(sortTypeIndex)
  }

  const handleSearch = (e: any) => {
    const value = e?.target?.value ?? e
    setSearchValue(value)
    let isIncluded: boolean
    setFilteredRows(installationRows.filter(row => {
      isIncluded = false
      refFilteringArray.forEach(f => {
        if (isIncluded || !f.checked)
          return
        isIncluded = JSON.stringify(row[f.key as keyof IInstallationRow]).toLowerCase().includes(value.toLowerCase())
      })
      return isIncluded
    }))
  }

  const handleFocus = (e: any) => e.target.select()

  const layoutToCards = () => setLayout(Layout.Cards)
  const layoutToTable = () => setLayout(Layout.Table)
  const openFilterPopover = (event: React.MouseEvent<HTMLButtonElement>) => setFilterAnchor(event.currentTarget)
  const openSortPopover = (event: React.MouseEvent<HTMLButtonElement>) => setSortAnchor(event.currentTarget)
  const closingPopover = () => { setSortAnchor(null); setFilterAnchor(null) }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = +event.target.id
    setRefFilteringArray(prev => {
      prev[id].checked = !prev[id].checked
      return [...prev]
    })
    handleSearch(searchValue)
  }

  return (
    <>
      <Stack
        width="100%"
        height="calc(100% - calc(var(--header-heigth)))"
        alignItems="center"
        overflow="auto"
      >
        {/* title */}
        <Grid minHeight="150px" className="center">
          <Typography
            textAlign="center"
            className="underline-title"
            width="fit-content"
            fontSize={30}
          >INSTALLATIONS</Typography>
        </Grid>
        {/* layout, filtering, search and sorting */}
        <Grid paddingY='5px' container flexWrap={'nowrap'}>
          {/* layout */}
          <Grid
            item
            xs={1}
            display={'flex'}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            gap={{ xs: 0, md: 1 }}
            color="white"
          >
            <Apps
              cursor="pointer"
              fontSize={layout === Layout.Cards ? "large" : "medium"}
              onClick={layoutToCards}
            />
            <TableRows
              cursor="pointer"
              fontSize={layout === Layout.Table ? "large" : "medium"}
              onClick={layoutToTable}
            />
          </Grid>
          {/* filtering, search and installation button */}
          <Grid
            item
            xs={10}
            display={'flex'}
            justifyContent="center"
            alignItems="center"
            gap={2}
            color={'white'}
          >
            <IconButton
              onClick={openFilterPopover}
              color="inherit"
              children={<FilterList cursor="pointer" fontSize="large" />}
            />
            <TextField
              value={searchValue}
              onChange={handleSearch}
              onFocus={handleFocus}
              sx={searchBarStyle}
            />
            <CreateInstallationDialog />
          </Grid>
          {/* sorting */}
          <Grid
            item
            xs={1}
            display={'flex'}
            justifyContent="center"
            alignItems='center'
            color="white"
          >
            <IconButton
              onClick={openSortPopover}
              color="inherit"
              children={<Sort cursor="pointer" fontSize="large" />}
            />
          </Grid>
        </Grid>
        {/* rows */}
        {filteredRows.length !== 0 &&
          <>
            {
              // as grid
              (layout == Layout.Cards && <InstallationsGrid filteredRows={filteredRows} />) ||
              // as table
              (layout == Layout.Table && <InstallationsTable filteredRows={filteredRows} />)
            }
          </>}
      </Stack>
      {/* filtering */}
      <Popover
        open={Boolean(filterAnchor)}
        anchorEl={filterAnchor}
        onClose={closingPopover}
        anchorOrigin={filterPopoverAnchorOrigin}
        transformOrigin={filterPopoverTransformOrigin}
        sx={popoverStyle}
      >
        <Stack alignItems='center' sx={popoverStackStyle}>
          <Grid item marginBottom='10px'><Typography>Filter by:</Typography></Grid>
          <FormGroup>
            {refFilteringArray.map((elem, i) => {
              return <FormControlLabel
                key={elem.key}
                label={elem?.label}
                control={
                  <Checkbox id={`${i}`}
                    checked={elem?.checked}
                    disabled={elem?.disabled}
                    onChange={handleCheckboxChange}
                    sx={checkBoxStyle}
                  />}
                sx={formControlLabelStyle}
              />
            }
            )}
          </FormGroup>
        </Stack>
      </Popover>
      {/* sorting */}
      <Popover
        open={Boolean(sortAnchor)}
        anchorEl={sortAnchor}
        onClose={closingPopover}
        anchorOrigin={sortPopoverAnchorOrigin}
        transformOrigin={sortPopoverTransformOrigin}
        sx={popoverStyle}
      >
        <Stack alignItems='center' sx={popoverStackStyle}>
          <Grid item marginBottom='10px'><Typography>Sort by:</Typography></Grid>
          {sortingArray.map((sortingFunction, index) =>
            index % 2 === 0 &&
            <Grid
              key={index}
              item
              container
              justifyContent='center'
              alignItems="center"
              onClick={sortingFunction.update}
              sx={gridItemStyle}
            >
              <Typography>{sortingFunction.name}</Typography>
              {sortingFunctionIndex === index ? <North /> : sortingFunctionIndex === index + 1 ? <South /> : null}
            </Grid>
          )}
        </Stack>
      </Popover>
    </>
  );
};

export default InstallationBody;