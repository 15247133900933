import { EHttpReturnType, EMethod, IQuery, IRestService } from '../../../common-interfaces/interfaces'
import { FormControl, InputLabel, MenuItem, Select, Stack, Tab, TextField, Typography } from '@mui/material'
import { deepCopy } from '../../../helpers/utils'
import BodyCreation from './rest/BodyCreation'
import useService from '../../../hooks/useService'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { FC, useEffect, useState } from 'react'
import RenderSplittedString from './common/RenderSplittedString'
import { useRecoilState } from 'recoil'
import { serviceParamsAtom } from '../../../recoil/Atom'
import { IRequestBody } from '../../../local-interfaces/local-interfaces'

const selectStyle = { fill: '#ccc', textAlign: 'center', color: '#ccc !important', letterSpacing: 1, textTransform: 'uppercase', '& .MuiSelect-icon': { fill: '#ccc' } }
const formControlStyle = { "& .MuiFormLabel-root, .Mui-focused, .MuiInputBase-root": { color: "#ccc !important" } }
const tabListStyle = { width: '100%', bgcolor: '#222' }
const tabStyle = { minWidth: '50%', height: '100%', color: '#ccc', fontSize: '17px', letterSpacing: 1 }

const EditRestService: FC<{ restService: IRestService }> = ({ restService }) => {
  const SERVICE = useService()

  const [serviceParams, setServiceParams] = useRecoilState<IQuery[]>(serviceParamsAtom);

  const [tabValue, setTabValue] = useState<'requestState' | 'requestSet'>("requestSet")
  const [tmpStateFieldToReturn, setTmpStateFieldToReturn] = useState<string>(restService.requestState.response.fieldToReturn);
  const [tmpStateFieldType, setTmpStateFieldType] = useState<EHttpReturnType>(restService.requestState.response.type);


  useEffect(() => {
    setServiceParams(restService[tabValue].params ?? [])
  }, [restService, tabValue])

  const dispatchMethodChange = async (event: any) => {
    const tmpRestService = await deepCopy(restService)
    tmpRestService.requestSet.method = event.target.value as EMethod
    SERVICE.updateServicesWith(tmpRestService, 'restService')
  }

  const dispatchBodyChange = async (updatedBody: IRequestBody) => {
    const tmpRestService = await deepCopy(restService)
    tmpRestService.requestSet.body = await deepCopy(updatedBody)
    if (serviceParams)
      tmpRestService.requestSet.params = [...serviceParams]
    SERVICE.updateServicesWith(tmpRestService, 'restService')
  }

  const handleTabChange = (_: React.SyntheticEvent, newValue: 'requestState' | 'requestSet') => {
    setTabValue(newValue);
  };

  const handleBlurOnTmpStateFieldToReturn = async (_: any) => {
    const tmpRestService: IRestService = await deepCopy(restService)
    tmpRestService[tabValue].response.fieldToReturn = tmpStateFieldToReturn
    SERVICE.updateServicesWith(tmpRestService, 'restService')
  }

  const handleTmpStateFieldType = async (value: EHttpReturnType) => {
    const tmpRestService: IRestService = await deepCopy(restService)
    tmpRestService[tabValue].response.type = value
    SERVICE.updateServicesWith(tmpRestService, 'restService')
  }

  const updateEndpoint = async (params: IQuery[], endpoint: string) => {
    const tmpRestService: IRestService = await deepCopy(restService)
    tmpRestService[tabValue].endpoint = endpoint
    tmpRestService[tabValue].params = [...params]
    SERVICE.updateServicesWith(tmpRestService, 'restService')
  }

  const updateFieldToReturn = async (params: IQuery[], modifyFieldToReturn: string) => {
    const tmpRestService: IRestService = await deepCopy(restService)
    tmpRestService[tabValue].response.fieldToReturn = modifyFieldToReturn
    tmpRestService[tabValue].params = [...params]
    SERVICE.updateServicesWith(tmpRestService, 'restService')
  }

  return (
    <TabContext value={tabValue}>
      <TabList sx={tabListStyle} onChange={handleTabChange} indicatorColor='secondary' textColor='secondary' variant='standard'>
        <Tab label="Command" value="requestSet" sx={tabStyle} />
        <Tab label="Feedback" value="requestState" sx={tabStyle} />
      </TabList>
      <TabPanel value={"requestSet"}>
        <Stack gap={3}>
          <RenderSplittedString dividerOnStart={false} label="Endpoint:" handleUpdate={updateEndpoint} inputString={restService.requestSet.endpoint} divider={'/'} />
          <FormControl variant='standard' sx={formControlStyle}>
            <InputLabel>Method</InputLabel>
            <Select variant="standard" value={restService.requestSet.method.toUpperCase()} fullWidth onChange={dispatchMethodChange} sx={selectStyle}>
              <MenuItem key={EMethod.PUT} value={EMethod.PUT}>{EMethod.PUT}</MenuItem>
              <MenuItem key={EMethod.POST} value={EMethod.POST}>{EMethod.POST}</MenuItem>
            </Select>
          </FormControl>
          <BodyCreation xs={9} dispatch={dispatchBodyChange} />
        </Stack>
      </TabPanel>
      <TabPanel value={"requestState"}>
        <RenderSplittedString dividerOnStart={false} label="Endpoint:" handleUpdate={updateEndpoint} inputString={restService.requestState.endpoint} divider={'/'} />
        <Typography color='#ccc' sx={{ mt: "5%" }}>Response:</Typography>
        <FormControl
          error={!tmpStateFieldType}
          size={"medium"}
          sx={{
            color: "var(--white-color)",
            ml: "1.5%",
            minWidth: "200px",
          }}
        >
          <InputLabel variant="standard" sx={{ color: '#ccc' }}>Type</InputLabel>
          <Select
            variant="standard"
            value={tmpStateFieldType}
            onChange={(e) =>
              setTmpStateFieldType(e.target.value as EHttpReturnType)
            }
            sx={{
              width: '95%',
              fill: 'white',
              textAlign: 'center',
              color: '#ccc !important',
              letterSpacing: 1,
              mx: 'auto',
              textTransform: 'uppercase',
              '& .MuiSelect-icon, .MuiSvgIcon-root': {
                fill: '#ccc'
              }
            }}
            onBlur={(e) =>
              handleTmpStateFieldType(e.target.value as EHttpReturnType)
            }
          >
            {Object.values(EHttpReturnType).map(e => <MenuItem key={e} value={e}>
              {e}
            </MenuItem>)}
          </Select>
        </FormControl>
        {tmpStateFieldType === EHttpReturnType.OBJECT ?
          <RenderSplittedString dividerOnStart={false} label="Field to return:" handleUpdate={updateFieldToReturn} inputString={restService.requestState.response.fieldToReturn} divider={'.'} /> :
          <TextField
            error={!tmpStateFieldToReturn}
            fullWidth
            label="Field to Return"
            variant="standard"
            value={tmpStateFieldToReturn}
            onChange={(e) => setTmpStateFieldToReturn(e.target.value)}
            onBlur={handleBlurOnTmpStateFieldToReturn}
            onKeyDown={(e: any) => {
              e.stopPropagation()
            }}
            sx={{
              ml: "1.5%",
              minWidth: "200px",
              input: { color: "#ccc" },
              label: { color: "#ccc" },
            }}
          />}
      </TabPanel>
    </TabContext >
  )
}

export default EditRestService