import { Button } from "@mui/material"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import { getCookie, PrintError } from "../../helpers/utils"
import { useApiWrapper } from "../../hooks/useApiWrapper"
import { ILoggedUser } from "../../local-interfaces/local-interfaces"
import { allModelsAtom, isLoadingAtom, loggedUserAtom, selectedInstallationAtom } from "../../recoil/atoms"
import { IInstallation } from "../../common-interfaces/interfaces"
import useOnInit from "../../hooks/useOnInit"

const FromCloud = () => {

  const apiWrapper = useApiWrapper()
  const navigate = useNavigate()

  const [loggedUser, setLoggedUser] = useRecoilState<ILoggedUser | undefined>(loggedUserAtom)
  const [allModels] = useRecoilState(allModelsAtom)
  const [, setCurrentInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom)
  const [, setIsLoading] = useRecoilState<boolean>(isLoadingAtom)
  const { getAllModels } = useOnInit()


  const handleRedirect = async () => {
    const plant = await JSON.parse(getCookie('currentPlant'))
    if (plant) {
      try {
        const installation = (await apiWrapper.postWithAuth(`api/installation/cloudRedirect`, { plant, installer: { ...loggedUser } })).data
        if (installation) {
          setCurrentInstallation(installation)
          if (!allModels) { await getAllModels() }
          setIsLoading(false)
          navigator(installation)
        }
      } catch (e) {
        PrintError([e])
      }
    }
  }

  const navigator = (installation: IInstallation) => {
    navigate(`/installation/${installation._id}`)
  }
  useEffect(() => {
    if (loggedUser)
      handleRedirect()
    else
      setLoggedUser({
        ...JSON.parse(getCookie('user')),
        issued: Date.now()
      })
  }, [loggedUser])

  return <>
  </>
}

export default FromCloud
