import { Tooltip, Select, MenuItem, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil';
import { IComponent, IInstallation, IService } from '../../../common-interfaces/interfaces';
import { selectedInstallationAtom } from '../../../recoil/atoms';
import { useOnSet } from '../../../hooks/useOnSet';
import { currentSelectedComponentAtom } from '../../../recoil/Atom';

const HandleHost: FC<{ service: IService }> = ({ service }) => {
  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [currentSelectedComponent] = useRecoilState<IComponent>(currentSelectedComponentAtom);
  const [currentService, setCurrentService] = useState<IService>(service)

  const SET = useOnSet()

  const handleChangeHost = async (newValue: string) => {
    const tmpService: IService = { ...service, host: newValue }
    setCurrentService(tmpService)
    SET.service(tmpService, currentSelectedComponent._id)
  };

  useEffect(() => {
    setCurrentService(service)
  }, [service]);

  const title = `${selectedInstallation.devices?.find(d => d.local_ip == currentService.host)?.hostname} - ${currentService.host}`
  return (
    <>
      {(selectedInstallation?.devices || selectedInstallation?.devices?.length > 0) ?
        // <Tooltip title={title} placement="top">
        <Select
          variant="standard"
          title={title}
          sx={{
            minWidth: '95%',
            maxWidth: '95%',
            color: '#ccc',
            '& .MuiSelect-icon': {
              fill: '#ccc'
            }
          }}
          disabled={!selectedInstallation?.devices}
          onChange={(e) => handleChangeHost(e.target.value)}
          value={currentService.host ?? 'Select a Device'}
        >
          {selectedInstallation?.devices.map((dev) => {
            return (
              <MenuItem key={dev._id} value={dev.local_ip} >
                {dev.hostname}
              </MenuItem>
            );
          })}
        </Select>
        // </Tooltip>
        : <Typography>Please Create a Device</Typography>}
    </>

  )
}
export default HandleHost