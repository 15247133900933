import { Box, Drawer, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import DocsSearch from './sidebar/DocsSearch';
import DocsTree from './sidebar/DocsTree';
import { useRecoilState } from 'recoil';
import { docsTreeAtom } from './common/DocsAtom';
import DocsContainer from './DocsContainer';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../../common-components/Layout/header/Header';

const DocsScreen = () => {

  const variante = useMediaQuery(useTheme().breakpoints.up('md')) ? "permanent" : "temporary";
  const [docsTree] = useRecoilState(docsTreeAtom)
  const [isOpen, setIsOpen] = useState(false);

  const drawerWidth = "300px";

  return (
    <Box height="100%" width="100%" display={'flex'}>
      {/* sidebar */}
      <Drawer
        variant={variante}
        open={isOpen}
        ModalProps={{ keepMounted: true }}
        onClose={() => setIsOpen(!isOpen)}
        sx={{
          width: drawerWidth,
          "& .MuiPaper-root": {
            width: drawerWidth,
            backgroundColor: 'var(--primary-color)',
            borderRight: { xs: 'none', md: '2px solid var(--selected-color)' },
          }
        }}
      >
        {/* search */}
        <Box width={'100%'} height={'calc(var(--header-heigth) + 2px)'} display={'flex'} justifyContent={'center'} alignItems={'end'}>
          <DocsSearch />
        </Box>
        {/* tree */}
        <Box width={'100%'} maxHeight={'calc(100% - var(--header-heigth) - 2px)'} overflow={'auto'} boxSizing={'border-box'} padding={"8px 0 8px 15px"}>
          {docsTree.length > 0 ? <DocsTree callable={setIsOpen} withValue={!isOpen} /> : <Typography color='white'>Sorry, no matches.</Typography>}
        </Box>
      </Drawer>
      {/* main */}
      <Box width={{ xs: "100%", md: `calc(100% - ${drawerWidth})` }} height={'100%'}>
        {/* header */}
        <Box width={'100%'} display={'flex'}>
          <IconButton
            size={'small'}
            onClick={() => setIsOpen(!isOpen)}
            sx={{
              boxSizing: 'border-box',
              paddingLeft: '15px',
              paddingRight: '0px',
              backgroundColor: 'var(--primary-color)',
              borderRadius: '0',
              borderBottom: '2px solid white',
              display: { xs: "flex", md: "none" },
              justifyContent: 'center',
              alignItems: 'center',
              ":hover": {
                backgroundColor: 'var(--primary-color)',
              },
            }}
          >
            <MenuIcon sx={{ color: 'white' }} />
          </IconButton>
          <Box flexGrow={1}>
            <Header />
          </Box>
        </Box>
        {/* documentation */}
        <Box width={"100%"} height={"calc(100% - var(--header-heigth))"} overflow={'auto'} color={'white'} boxSizing={'border-box'} padding={'10px'}>
          <DocsContainer />
        </Box>
      </Box>
    </Box >
  );
};

export default DocsScreen;
