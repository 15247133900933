import { Box, ButtonGroup, Stack, Typography } from "@mui/material";
import HandleDelete from "../../../screens/Dashboard/rightBar/common/HandleDelete";
import { useRecoilState } from "recoil";
import HandleSave from "../../../screens/Dashboard/rightBar/oneComponent/HandleSave";
import { loggedUserAtom } from "../../../recoil/atoms";
import HandleAdminSave from "../../../screens/Dashboard/rightBar/oneComponent/HandleAdminSave";
import EditComponentInfo from "./EditComponentInfo";
import HandleCopy from "../../../screens/Dashboard/rightBar/oneComponent/HandleCopy";
import { useParams } from "react-router-dom";
import ServicesTable from "./ServicesTable";
import { currentSelectedComponentAtom } from "../../../recoil/Atom";
import ShareButton from "../../common/ShareButton";
import { ERequestSubtype } from "../../../common-interfaces/interfaces";
import HandleHide from "../../../screens/Dashboard/rightBar/oneComponent/HandleHide";

const EditOneComponent = () => {
  const [currentSelectedComponent] = useRecoilState(currentSelectedComponentAtom)
  const [loggedUser] = useRecoilState(loggedUserAtom);
  const { componentId } = useParams();

  return (
    <Stack width={"100%"} height={"100%"} alignItems={'center'} gap={2}>
      <Typography className="underline-title center" mt={"5%"}>Component</Typography>
      {!componentId &&
        <ButtonGroup>
          <HandleHide />
          <HandleDelete />
          <HandleSave />
          <ShareButton argument={ERequestSubtype.COMPONENT} body={currentSelectedComponent} styleProps={{ color: "white", border: "1px solid #666" }} />
          <HandleCopy />
          {/* K-ARRAY SAVE3 NEED TO CHECK ROLE */}
          {loggedUser?.role === "admin" && <HandleAdminSave />}
        </ButtonGroup>}
      <Typography fontSize={"0.2rem"} color={"#7d7a7a"}>{currentSelectedComponent?._id}</Typography>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'space-around'} alignItems={'center'} gap={1} flexGrow={1}>
        <EditComponentInfo />
        {/* only if is not a master */}
        {!currentSelectedComponent?.slaves?.length && <ServicesTable />}
      </Box>
    </Stack>
  );
}

export default EditOneComponent
