import { Box, Grid } from "@mui/material";
import EditComponentHandler from "./EditComponentHandler";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { loggedUserAtom, selectedComponentIDsAtom } from "../../../recoil/atoms";
import { useOnGet } from "../../../hooks/useOnGet";
import { EComponentType, IComponent } from "../../../common-interfaces/interfaces";
import { IAutocompleteOPT } from "../../../local-interfaces/local-interfaces";

export default function EditComponentInfo() {
  const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);
  const [logged_user] = useRecoilState(loggedUserAtom);
  const [comp, setComp] = useState<IComponent>();
  const [ICONS, setIcons] = useState<IAutocompleteOPT[]>([]);
  const GET = useOnGet();

  useEffect(() => {
    if (selectedComponentIDs === comp?._id) return;
    const editComp: IComponent | undefined = GET.comp(selectedComponentIDs[0]);
    editComp && setComp(editComp);
  }, [selectedComponentIDs, GET]);

  const getAllImages = async () => {
    const tmpIcons = await GET.images();
    setIcons(tmpIcons);
  };

  useEffect(() => {
    getAllImages();
  }, [logged_user?.images]);

  return (
    <Grid container width={'90%'}>
      {/* Name Field */}
      <Grid item xs={12}>
        <EditComponentHandler
          type="input"
          disabled={false}
          edit="name"
          w="100%"
          borderTop="1px solid var(--grey-color)"
        />
      </Grid>

      {/* Description Field */}
      <Grid item xs={12}>
        <EditComponentHandler
          type="input"
          disabled={false}
          edit="description"
          w="100%"
        />
      </Grid>

      {/* Color Pickers and Icon Selector Row */}
      <Grid item container xs={12}>
        <Grid item xs={6} md={3}>
          <EditComponentHandler
            type="colorpicker"
            disabled={false}
            edit="backgroundColor"
            w="100%"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <EditComponentHandler
            type="colorpicker"
            disabled={false}
            edit="color"
            w="100%"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {(comp?.type === EComponentType.Button || comp?.type === EComponentType.Switch) && (
            <EditComponentHandler
              type="select"
              opts={ICONS}
              disabled={false}
              edit="icon"
              w="100%"
            />
          )}
          {comp?.type === EComponentType.Slider && (
            <Grid container xs={12}>
              <Grid item xs={6}>
                <EditComponentHandler
                  type="number"
                  edit="min"
                  w="100%"
                />
              </Grid>
              <Grid item xs={6}>
                <EditComponentHandler
                  type="number"
                  edit="max"
                  w="100%"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Slider Fields for Min and Max (conditionally rendered) */}

    </Grid>
  );
}
