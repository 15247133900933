import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  IComponent,
  IDashboard,
  IInstallation,
  IUser,
} from "../common-interfaces/interfaces";
import {
  allComponentsAtom,
  loggedUserAtom,
  selectedComponentIDsAtom,
  selectedDashboardAtom,
  selectedInstallationAtom,
  userProfileAtom,
} from "../recoil/atoms";
import ObjectID from "bson-objectid";
import { useApiWrapper } from "./useApiWrapper";
import { selectedContainerAtom } from "../recoil/workspace";
import { EButtonIcons } from "../local-interfaces/local-enum";
import { IAutocompleteOPT } from "../local-interfaces/local-interfaces";
import { useState } from "react";

export function useOnGet() {
  const [selectedInstallation] = useRecoilState<IInstallation>(
    selectedInstallationAtom
  );
  const [selectedComponentIDs] = useRecoilState<string[]>(
    selectedComponentIDsAtom
  );
  const [allComponents] = useRecoilState(allComponentsAtom);
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [selectedContainer] = useRecoilState(selectedContainerAtom);
  const [logged_user] = useRecoilState(loggedUserAtom);
  const [user, setUser] = useRecoilState<IUser | undefined>(userProfileAtom)
  let { dashboardId } = useParams();

  const apiWrapper = useApiWrapper();

  return {
    installation: getInstallation(),
    dash: getDash,
    remoteDash: getRemoteDash(),
    comp: getComp(),
    id: getID,
    user: getUser,
    selectedComponents: selectedComponents,
    images: getAllImages,
  };

  function getDash(): IDashboard | undefined {
    if (!selectedInstallation)
      return;
    return selectedInstallation?.configuration.dashboards.find(d => d._id === dashboardId)
  }

  function getComp(): (compID: string) => IComponent | undefined {
    return (compID): IComponent | undefined => {
      let tempComp = selectedDashboard?.components?.find(
        (c) => c._id === compID
      ); //cerco prima nella dash
      if (tempComp) {
        return tempComp;
      }
      if (selectedContainer) {
        let container: IComponent = selectedContainer;
        let compInside = container?.components?.find((x) => x._id == compID); // poi nel contenitore
        if (compInside) {
          return compInside;
        }
      }
      if (!allComponents) return undefined;
      let comp = allComponents.find((c) => c._id == compID); // ritorno poi da all comp
      return comp ? comp : undefined;
    };
  }

  function getInstallation(): (
    installationID: string
  ) => Promise<IInstallation> {
    return async (installationID): Promise<IInstallation> => {
      const url = `api/installation?_id=${installationID}`;
      const res = await apiWrapper.getWithAuth(url, {});
      return res.data[0];
    };
  }
  function getRemoteDash(): (
    dashId: string
  ) => Promise<IDashboard> {
    return async (installationID): Promise<IDashboard> => {
      const url = `api/dashboard?_id=${installationID}`;
      const res = await apiWrapper.getWithAuth(url, {});
      return res.data[0];
    };
  }

  function getID(): string {
    const id = ObjectID().toHexString();
    return id.toString();
  }

  function selectedComponents() {
    if (selectedContainer && selectedContainer?.components) {
      return selectedContainer?.components.filter((comp: IComponent) => {
        if (selectedComponentIDs.includes(comp._id)) return comp;
      });
    }
    let selectedDashboard = getDash();
    if (!selectedDashboard) return;
    return selectedDashboard?.components.filter((comp: IComponent) => {
      if (selectedComponentIDs.includes(comp._id)) return comp;
    });
  }

  async function getUser(): Promise<IUser> {
    if(user) return user
    const url = `api/user?username=${encodeURIComponent(logged_user?.username ?? '')}`;
    const res = await apiWrapper.getWithAuth(url, {});
    const tmpUser = res.data[0];
    setUser(tmpUser)
    return tmpUser;
  }

  async function getAllImages(): Promise<IAutocompleteOPT[]> {
    const ALL_DEFAULT_ICONS: IAutocompleteOPT[] = Object.entries(
      EButtonIcons
    ).map(([label, value]) => ({ label, value }));
    // REMOVE FOR USER ICONS
    // const user = await getUser();
    // const USER_CUST_ICON: IAutocompleteOPT[] = user.images
    //   ? user.images.map((img) => ({
    //     label: img.name,
    //     value: img.src,
    //   }))
    //   : [];
    return [...ALL_DEFAULT_ICONS
      // , ...USER_CUST_ICON
    ];
  }
}
