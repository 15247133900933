import { Box, Button, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { isLoadingAtom } from "../../../recoil/atoms";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Loading from "react-loading";
import "../../../styles/CentralBox.css";
import HandleAddDevice from "../../../common-components/devices/HandleAddDevice";


const CentralActions = (props: any) => {
  const [isLoading] = useRecoilState<boolean>(isLoadingAtom);

  if (isLoading) return <Loading></Loading>;

  return (
    <Box
      sx={{ width: "100%", height: "100%" }}
      display={'flex'}
      gap={1}
      justifyContent={'flex-end'}
      pt={'10px'}
      pr={'5%'}
    >
      <HandleAddDevice />
      <Button
        className="addDashboardButton"
        onClick={async () => await props.handleDashboardCreation()}
      >
        <Typography mr={0.5} fontSize={13}>Dashboard</Typography>
        <AddCircleOutlineIcon sx={{ display: 'flex', mb: 0.25 }} fontSize="small" />
      </Button>
    </Box>
  );
};
export default CentralActions;
