import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import { wizardSelectedPlantAtom } from "../../../recoil/CreateInstallationAtoms";
import { IPlantFull, IPlantUser } from "../../../common-interfaces/interfaces";
import { deepCopy } from "../../../helpers/utils";
import { ILoggedUser, IUniqueClient } from "../../../local-interfaces/local-interfaces";
import { allPlantsFullAtom, allUsersAtom, loggedUserAtom } from "../../../recoil/atoms";
import { useApiWrapper } from "../../../hooks/useApiWrapper";

export default function LinkInstallationToClient() {
  const [selectedPlant, setSelectedPlant] = useRecoilState<IPlantFull | undefined>(wizardSelectedPlantAtom);
  const [allPlantsFull] = useRecoilState<IPlantFull[]>(allPlantsFullAtom);
  const [selectedClients, setSelectedClients] = useState<IClientForSelect[]>([]);
  const [allUsersForSelect, setAllUsersForSelect] = useState<IClientForSelect[]>([]);
  const [allUsers] = useRecoilState<IPlantUser[]>(allUsersAtom);
  const [loggedUser] = useRecoilState<ILoggedUser | undefined>(loggedUserAtom);
  const [, setEmailFrom] = useState<string>("");
  const [clientErr, setClientErr] = useState(false);
  const GET = useApiWrapper();

  const getInstallerEmail = async () => {
    if (!loggedUser) return;
    const USER = await GET.getWithAuth(
      `api/user?username=${loggedUser.username}`,
      {}
    );
    setEmailFrom(USER.data[0].username);
  };

  const checkIfClientWasPresent = (newClient: IClientForSelect) => {
    if (
      selectedPlant?.clients.find(
        (client) => client.user.email === newClient.email
      ) &&
      !selectedClients?.find((c) => c.email === newClient.email)
    ) {
      return newClient;
    }
  };

  const initClientsForSelect = () => {
    let userForSelect: IClientForSelect[] = allUsers
      .map((u) => {
        const firstLetter = u.user.first_name[0].toUpperCase();
        const newClient = {
          //regex for numbers
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          fullname: `${u?.user.first_name} ${u?.user.last_name}`,
          email: u.user.email,
        };
        return newClient;
      })
      .filter(Boolean);
    setAllUsersForSelect(userForSelect);
    const tempSelectedClients = userForSelect.filter((client) =>
      checkIfClientWasPresent(client)
    );
    setSelectedClients(tempSelectedClients);
  };

  useEffect(() => {
    if (allUsers) {
      initClientsForSelect();
    }
    if (allPlantsFull?.length > 0) {
      setEmailFrom(allPlantsFull[0].installer.user.email);
    } else {
      getInstallerEmail();
    }
  }, [allUsers, allPlantsFull]);

  const handleClientSelection = async (
    ev: any,
    selected: IClientForSelect[]
  ) => {
    if (!selected && !ev.target.value) return setClientErr(true);
    let tmpSelectedClients: IClientForSelect[] =
      selected !== null ? selected : ev.target.value;
    setSelectedClients(tmpSelectedClients);
    let tempClients = allUsers.filter((u) =>
      tmpSelectedClients.find((client) => u.user.email === client.email)
    );
    if (tempClients) {
      let tempPlant: IPlantFull = selectedPlant
        ? await deepCopy(selectedPlant)
        : {};
      tempPlant.clients = await deepCopy(tempClients);
      setSelectedPlant(tempPlant);
    }
  };

  return (
    <Box>
      <Box>
        <Autocomplete
          multiple
          value={selectedClients || []}
          options={allUsersForSelect.filter(
            (opt) =>
              !selectedClients?.find((client) => client.email === opt.email)
          )}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.fullname}
          sx={{ pt: 5 }}
          onChange={(ev, selectedValue) =>
            handleClientSelection(ev, selectedValue)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              error={clientErr}
              label="Clients"
              variant="standard"
              sx={[
                {
                  ml: 3,
                  maxWidth: "400px",
                  minWidth: "400px",
                },
                {
                  "& .Mui-focused": {
                    color: "#fff !important",
                  },
                },
              ]}
            />
          )}
          PaperComponent={CustomPaper}
          renderGroup={renderGroup}
        />
      </Box>
    </Box>
  );
}

interface IClientForSelect extends IUniqueClient {
  firstLetter: string;
}

//utils for render the first letter
const renderGroup = (params: any) => [
  <li key={params.key}>
    <span style={{ color: "var(--secondary-color)", marginLeft: "10px" }}>
      {params.group}
    </span>
  </li>,
  params.children,
];
//render the bg of select
const CustomPaper = (props: any) => (
  <Paper
    {...props}
    sx={{
      backgroundColor: "#222",
      border: "var(--primary_border)",
      color: "var(--grey_background-color)",
    }}
  />
);
