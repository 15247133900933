import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Message } from "@mui/icons-material";
import { Badge, IconButton } from "@mui/material";
import usePopover from "../../common/usePopover";
import { useRecoilState } from "recoil";
import { allRequestsAtom } from "../../utils/requestAtoms";
import MessagePopover from "./MessagePopover";
import { useOnGet } from "../../../../hooks/useOnGet";
import { INotificationRequest } from "../../../../common-interfaces/interfaces";

const ViewMessageButton: FC<{ requestID: string }> = ({ requestID }) => {
  const [allRequests] = useRecoilState(allRequestsAtom);
  const [userEmail, setUserEmail] = useState<string>();
  const [messageCount, setMessageCount] = useState<number>(0);
  const messageCountRef = useRef<number>(0);
  const { user } = useOnGet();

  useEffect(() => {
    const getUserEmail = async () => {
      const tmpUser = await user();
      setUserEmail(tmpUser.username);
    };
  }, []);


  useEffect(() => {
    if (userEmail) {
      getNewMessageLength();
    }
  }, [allRequests, userEmail]);


  const countPendingMessages = useCallback(
    (request: INotificationRequest) => {
      const isFromInstaller = request.from === userEmail;
      !isFromInstaller ? messageCountRef.current++ : messageCountRef.current = 0
      request.subrequests?.forEach(subrequest => countPendingMessages(subrequest));
    },
    [userEmail]
  );

  const getNewMessageLength = useCallback(() => {
    const req = allRequests.find(r => r._id === requestID);
    messageCountRef.current = 0;
    if (req) {
      countPendingMessages(req);
      setMessageCount(messageCountRef.current);
    }
  }, [allRequests, userEmail, countPendingMessages]);



  const messagePopover = usePopover();

  return (
    <>
      <Badge
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        overlap='circular'
        variant='standard'
        badgeContent={messageCount}
        color='secondary'
      >
        <IconButton
          size='large'
          color='inherit'
          sx={{ paddingY: '2px' }}
          onClick={(e: any) => messagePopover.open(e.currentTarget)}
        >
          <Message />
        </IconButton>
      </Badge>
      {userEmail && messagePopover.isOpen && (
        <MessagePopover
          requestID={requestID}
          userEmail={userEmail}
          open={messagePopover.isOpen}
          anchorEl={messagePopover.anchor}
          onClose={messagePopover.close}
        />
      )}
    </>
  );
}

export default ViewMessageButton;
