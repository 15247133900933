import { Grid, IconButton, Typography } from "@mui/material";
import { useDrag } from "react-dnd";
import { useRecoilState } from "recoil";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { deepCopy } from "../../../helpers/utils";
import { useOnGet } from "../../../hooks/useOnGet";
import { useOnSet } from "../../../hooks/useOnSet";
import { EServiceType, IComponent, IService } from "../../../common-interfaces/interfaces";
import { useApiWrapper } from "../../../hooks/useApiWrapper";
import { ItemTypes } from "../../Dashboard/workspace/dnd/utils/dndUtils";
import { FC } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { currentSelectedComponentAtom, uneditableServiceAtom, uniquesDurationAtom } from "../../../recoil/Atom";
import TooltipOverflow from "../../../common-components/common/TooltipOverflow";
import { oscColor, restColor } from "./servicesColor";
import { enqueueSnackbar } from "notistack";
import { IUniquesDuration } from "../../../local-interfaces/local-interfaces";
import { SuccessAutoHide } from "../../../config/general.config";

const containerStyle = { cursor: "grabbing" }
const iconButtonStyle = { padding: 0 }

const DndService: FC<{ service: IService }> = ({ service }) => {
  const GET = useOnGet();
  const SET = useOnSet();
  const apiWrapper = useApiWrapper();

  const [allServices, setAllServices] = useRecoilState<IService[]>(uneditableServiceAtom);
  const [selectedDuration] = useRecoilState<IUniquesDuration>(uniquesDurationAtom)
  const [comp, setComp] = useRecoilState(currentSelectedComponentAtom)

  const makeServiceCopy = (s: IService) => {
    s.origin = s._id
    s._id = GET.id()
    s.editable = true
    s.duration = selectedDuration.selectedDurationIndex
    s.brand = 'custom'
    return s
  }

  const handleServiceDoubleClick = async () => {
    const tmpService = await deepCopy(service)
    const tempComp: IComponent = await deepCopy(comp)
    tempComp.services = [...(tempComp.services ?? []), makeServiceCopy(tmpService)]
    setComp(tempComp)
    SET.comp(tempComp)
  }

  const deleteService = async (id: string) => {
    id && await apiWrapper.deleteWithAuth(`api/service/${id}`, {});
  };

  const handleDeleteCustomService = async () => {
    if (!allServices)
      return;
    await deleteService(service._id);
    let tmpServices = allServices.filter(s => s._id !== service._id);
    setAllServices(tmpServices);
    enqueueSnackbar('deleted correctly', { variant: 'info', autoHideDuration: SuccessAutoHide })
  };

  const DndIcon = () => {
    // switch (service.protocol) {
    //   case EServiceType.Rest:
    //     return <img src={img} alt="rest" style={{ width: "35px" }} />;
    //   default:
    //     return <EqualizerIcon fontSize="small" />;
    //   }
    return <EqualizerIcon fontSize="small" />;
  };

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.PREVIEW,
    item: { service },
    previewOptions: {
      anchorX: 0.5,
      anchorY: 0.5,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: () => { },
  }));

  return (
    <>
      <Grid ref={drag} key={service._id} width="100%" container justifyContent={'space-between'} alignItems="center" borderBottom="1px solid" boxSizing={'border-box'} padding="5px 15px" marginBottom="5px" sx={containerStyle} onDoubleClick={handleServiceDoubleClick}>
        <Grid item xs={9} container>
          {service.brand === "custom" && (
            <Grid item xs={2}>
              <IconButton sx={iconButtonStyle} color="error" onClick={handleDeleteCustomService}>
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={10}>
            <TooltipOverflow
              tooltipChildren={
                <Typography
                  color="var(--light_grey-color)"
                  fontSize="14px"
                  textTransform="uppercase"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  children={service.name}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid ref={preview} item xs={3} boxSizing="border-box" padding="5px" border="1px solid #6b6868" borderRadius={1} zIndex={2} color="var(--light_grey-color)" bgcolor={service.protocol === EServiceType.Rest ? restColor : oscColor} className="center">
          <DndIcon />
        </Grid>
      </Grid>
    </>
  );
}

export default DndService