import React, { useState } from 'react';
import { Popover, Typography, DialogTitle, DialogContent, PopoverProps, Box, Button, CircularProgress } from '@mui/material';
import { ADMINTOOOL_Value } from './adminToolConfig';
import { enqueueSnackbar } from "notistack";
import { PrintError } from '../../helpers/utils';

interface messagePopoverProps extends PopoverProps {
  item: any;
  type: ADMINTOOOL_Value;
}

const popoverStyle = {
  "& .MuiPaper-root": {
    backgroundColor: 'var(--primary-color)',
    color: 'white',
    borderColor: 'white',
    width: '40vw'
  }
};

const syntaxHighlight = (json: any) => {
  if (typeof json !== 'string') {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?)|(\b(true|false|null)\b)|(-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, (match: any) => {
    let cls = 'number';
    if (/^"/.test(match)) {
      if (/:$/.test(match)) {
        cls = 'key';
      } else {
        cls = 'string';
      }
    } else if (/true|false/.test(match)) {
      cls = 'boolean';
    } else if (/null/.test(match)) {
      cls = 'null';
    }
    return `<span class="${cls}">${match}</span>`;
  });
};

const AdminToolPopOver: React.FC<messagePopoverProps> = ({ item, type, open, anchorEl, onClose }) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const jsonString = JSON.stringify(item, null, 2);

  const copyToClipboard = async (text: string) => {
    setDisabled(true);
    setLoading(true);
    try {
      await navigator.clipboard.writeText(text);
      enqueueSnackbar('JSON copied to clipboard!', { variant: 'success', autoHideDuration: 750 });
    } catch (err) {
      PrintError(['Failed to copy: ', err]);
      enqueueSnackbar('Failed to copy JSON', { variant: 'error', autoHideDuration: 750 });
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={popoverStyle}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography>{item.name}</Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={() => copyToClipboard(jsonString)}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : `Copy ${type.toUpperCase()}`}
        </Button>
      </DialogTitle>
      <DialogContent dividers sx={{ background: '#333' }}>
        <Box
          component="pre"
          sx={{
            maxHeight: '50vh',
            overflow: 'auto',
            '& .key': { color: '#9cdcfe' },
            '& .string': { color: '#ce9178' },
            '& .number': { color: '#b5cea8' },
            '& .boolean': { color: '#4fc1ff' },
            '& .null': { color: '#d33350' }
          }}
          dangerouslySetInnerHTML={{ __html: syntaxHighlight(item) }}
        />
      </DialogContent>
    </Popover>
  );
};

export default AdminToolPopOver;
