import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilStoreID } from "recoil";
import { useApiWrapper } from "./useApiWrapper";
import { useOnGet } from "./useOnGet";
import { deepCopy, filterUniqueFromArray, Print } from "../helpers/utils";
import { IInstallation, IComponent, IPlantFull, IPlantUser, IDashboard, IService } from "../common-interfaces/interfaces";
import { ILoggedUser } from "../local-interfaces/local-interfaces";
import { allComponentsAtom, allModelsAtom, allPlantsFullAtom, allUsersAtom, isLoadingAtom, loggedUserAtom, selectedDashboardAtom, selectedInstallationAtom } from "../recoil/atoms";
import { currentSelectedComponentAtom, selectedServiceAtom, uneditableDashboardAtom, uneditableServiceAtom, uniquesDurationAtom } from "../recoil/Atom";
import useResetAllAtoms from "./useResetAllAtoms";
import { IProduct } from "../common-interfaces/indexInterfaces";
import axios from "axios";
import { IUniquesDuration } from "../local-interfaces/local-interfaces";

export default function useOnInit() {
  const [loggedUser] = useRecoilState<ILoggedUser | undefined>(loggedUserAtom);

  const [allComponents, setAllComponents] = useRecoilState<IComponent[]>(allComponentsAtom);
  const [allServices, setAllServices] = useRecoilState<IService[]>(uneditableServiceAtom);
  const [uneditableDash, setUneditableDash] = useRecoilState<IDashboard[]>(uneditableDashboardAtom)
  const [allPlantsFull, setAllPlantsFull] = useRecoilState<IPlantFull[]>(allPlantsFullAtom);
  const [allModels, setAllModels] = useRecoilState<IProduct[]>(allModelsAtom)
  const [allUsers, setAllUsers] = useRecoilState<IPlantUser[]>(allUsersAtom);
  const [,setSelectedService] = useRecoilState<IService>(selectedServiceAtom)

  const [selectedInstallation, setSelectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [selectedDashboard, setSelectedDashboard] = useRecoilState<IDashboard>(selectedDashboardAtom);
  const [currentSelectedComponent, setCurrentSelectedComponent] = useRecoilState<IComponent>(currentSelectedComponentAtom);
  const [, setIsLoading] = useRecoilState<boolean>(isLoadingAtom);
  const [, setUniquesDuration] = useRecoilState<IUniquesDuration>(uniquesDurationAtom);

  const { installationId, dashboardId, componentId } = useParams();
  const apiWrapper = useApiWrapper();
  const GET = useOnGet();


  const { resetAll } = useResetAllAtoms();

  const handleError = () => {
    resetAll();
    return;
  };

  const initPlant = async () => {
    setIsLoading(true)
    if (!loggedUser) {
      handleError();
      return;
    }
    try {
      await Promise.all([
        (!allPlantsFull || allPlantsFull.length === 0) && getAllPlants(),
        (!allUsers || allUsers.length === 0) && getAllUsers(),
        (!uneditableDash || uneditableDash.length === 0) && getAllNoEditableDash(),
        (!allComponents || allComponents.length === 0) && getAllNoEditableComps(),
        (!allServices || allServices.length === 0) && getAllNoEditableServives(),
        (!allModels || allModels.length === 0) && getAllModels()
      ]);
    } catch (error) {
      handleError();
    }
  };


  const initInstallation = async () => {
    if (!installationId) {
      handleError();
      return;
    }

    try {
      let newInstallation: IInstallation = selectedInstallation ?? await getCurrentInstallation(installationId);
      if (!newInstallation) {
        handleError();
        return;
      }
      setSelectedInstallation(newInstallation);

      if (!dashboardId || selectedDashboard) {
        return;
      }
      const tmpDash = newInstallation?.configuration?.dashboards.find(dash => dash._id === dashboardId);
      if (!tmpDash) {
        handleError();
        return;
      }
      setSelectedDashboard(await deepCopy(tmpDash));

      if (componentId && !currentSelectedComponent) {
        initCompSection(tmpDash);
      }

    } catch (error) {
      handleError();
    }
  };

  const initCompSection = (tmpDash: IDashboard) => {
    const comp = tmpDash.components.find(c => c._id === componentId);
    if (comp) {
      setCurrentSelectedComponent(comp);
      if (comp.services) {
        const uniques = filterUniqueFromArray(comp.services.map(service => service.duration ?? 0));
        if (uniques.length > 0)
          setUniquesDuration((current) => { return { ...current, durations: [...uniques] } })
        setSelectedService(comp.services[0])
      }
    }
  };


  const getAllPlants = async () => {
    const res = await apiWrapper.getWithAuth(`api/plant`, {});
    if (!res || !res.data) return [];
    Print(["%c > All Plants:", "background: #a2a; color: #fff", res.data]);
    setAllPlantsFull(res.data)
  };

  const getCurrentInstallation = async (installationID: string) => {
    const res = await GET.installation(installationID);
    Print([
      "%c >>> Current Installation:",
      "background: #00a49e; color: black",
      res,
    ]);
    return res;
  };

  const getAllNoEditableComps = async () => {
    const url = `api/component?editable=false`;
    const tmpComponents = await apiWrapper.getWithAuth(url, {});

    if (tmpComponents.data) {
      setAllComponents(tmpComponents.data);
    }
    Print([
      "%c >>> All Components:",
      "background: cadetblue; color: #fff",
      tmpComponents.data,
    ]);
  };

  const getAllNoEditableDash = async () => {
    const url = `api/dashboard?editable=false`;
    const tmpDashs = await apiWrapper.getWithAuth(url, {});

    if (tmpDashs.data) {
      setUneditableDash(tmpDashs.data);
    }
    Print([
      "%c >>> All Components:",
      "background: purple; color: #fff",
      tmpDashs.data,
    ]);
  };

  const getAllNoEditableServives = async () => {
    const url = `api/service?editable=false`;
    const services = await apiWrapper.getWithAuth(url, {});
    const tmpService: IService[] = [...services.data]
    setAllServices(tmpService);
  };

  const getAllModels = async () => {
    const url = `https://index-be.k-array.com/public/models`;
    const tmpModels = await axios.get(url, {});

    if (tmpModels.data) {
      setAllModels(tmpModels.data.filter((d: IProduct) => +d.year >= 2021 && d.visible));
    }
    Print([
      "%c >>> All MODELS:",
      "background: cadetblue; color: #fff",
      tmpModels.data,
    ]);
  }

  const getAllUsers = async () => {
    const res = await apiWrapper.getWithAuth(`api/friendship?email_from=${encodeURIComponent(loggedUser?.username ?? '')}`, {});
    if (!res || !res.data) return [];
    Print([
      "%c >>> All Users with Partnership:",
      "background: #445FF1; color: #fff",
      res.data,
    ]);
    setAllUsers(res.data);
  };

  const initAll = async () => {
    try {
      setIsLoading(true);
      await initPlant();
      await initInstallation();
    } catch (error) {
      handleError();
    } finally {
      setIsLoading(false);
    }
  };

  return {
    all: initAll,
    componentSection: initCompSection,
    reset: resetAll,
    getAllModels: getAllModels,
    initPlant: initPlant
  };
}
