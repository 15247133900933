import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { selectedDashboardAtom } from "../../../recoil/atoms";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../Dashboard/workspace/dnd/utils/dndUtils";
import { IComponent, IService } from "../../../common-interfaces/interfaces";
import ServiceBox from "./ServiceBox";
import { currentSelectedComponentAtom, selectedServiceAtom } from "../../../recoil/Atom";
import { useOnGet } from "../../../hooks/useOnGet";
import { deepCopy } from "../../../helpers/utils";
import { useOnSet } from "../../../hooks/useOnSet";

const ServiceLevel: FC<{ duration: number }> = ({ duration }) => {

  const GET = useOnGet();
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [currentSelectedComponent, setCurrentSelectedComponent] = useRecoilState<IComponent>(currentSelectedComponentAtom)
  const [services, setServices] = useState<IService[]>([])

  const resetSelectedService = useResetRecoilState(selectedServiceAtom)
  const SET = useOnSet()

  useEffect(() => {
    currentSelectedComponent?.services && setServices([...currentSelectedComponent.services])
  }, [currentSelectedComponent]);

  function makeServiceCopy(s: IService) {
    let service = { ...s }
    service.origin = service._id
    service._id = GET.id()
    service.editable = true
    service.duration = duration
    service.brand = 'custom'
    return service
  }

  const [{ isOver, canDrop }, drop] = useDrop(
    //DRAG AND DROP
    () => ({
      accept: [ItemTypes.COMPONENT, ItemTypes.PREVIEW],
      drop: async (item: any) => {
        const tempComp: IComponent = await deepCopy(currentSelectedComponent)
        tempComp.services = [...(tempComp.services ?? []), makeServiceCopy(item.service)]
        setCurrentSelectedComponent(tempComp)
        SET.comp(tempComp)
      },

      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
        ItemType: monitor.getItemType(),
      }),
    }),
    [
      selectedDashboard,
      services,
      duration
    ]
  );

  return (
    <Grid
      container
      item
      ref={drop}
      key={`${duration}`}
      id={`${duration}`}
      bgcolor={'#595959'}
      height={'100%'}
      boxSizing={'border-box'}
      onClick={resetSelectedService}
      overflow={'auto'}
    >
      {services &&
        // workaround temporaneo
        <Grid container direction="row"
          height="275px"
        >
          {services
            // .filter((service) => service.duration === duration)
            .map((filteredService) => (
              <Grid
                item
                sm={12}
                md={6}
                xl={3}
                key={filteredService._id}
                color="white"
                height={'100%'}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ServiceBox service={filteredService} />
              </Grid>
            ))}
        </Grid>
      }
    </Grid>

  );
};

export default ServiceLevel
