import { IService } from "../../../common-interfaces/interfaces";
import { currentSelectedComponentAtom, selectedServiceAtom } from "../../../recoil/Atom";
import { Grid } from "@mui/material";
import { useRecoilState } from "recoil";
import ServiceInfo from "./ServiceInfo";
import EditOneComponent from "../../../common-components/editComponents/editOneComponent/EditOneComponent";
import { selectedComponentIDsAtom } from "../../../recoil/atoms";
import { useEffect } from "react";

const EditComponentRightBar = () => {
  const [selectedService] = useRecoilState<IService>(selectedServiceAtom);
  const [selectedComponentIDs, setSelectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom)
  const [comp] = useRecoilState(currentSelectedComponentAtom)

  return (
    <Grid
      item
      xs={3}
      height={"100%"}
      className="scrollable"
      bgcolor={"var(--primary-color)"}
    >
      {selectedService ?
        <ServiceInfo /> :
        comp ?
          <EditOneComponent />
          : <></>
      }
    </Grid>
  );
};
export default EditComponentRightBar;
