import { Button } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  IComponent,
  IDashboard,
  IInstallation,
} from "../../../../common-interfaces/interfaces";
import {
  selectedComponentIDsAtom,
  selectedInstallationAtom,
} from "../../../../recoil/atoms";
import { useOnGet } from "../../../../hooks/useOnGet";
import { deepCopy } from "../../../../helpers/utils";
import { enqueueSnackbar } from "notistack";
import { ErrorAutoHide } from "../../../../config/general.config";
import { useOnSet } from "../../../../hooks/useOnSet";
import { useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


export default function HandleCopy() {
  const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);
  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [selectedComponent, setSelectedComp] = useState<IComponent>()
  const GET = useOnGet();
  const SET = useOnSet()


  useEffect(() => {
    const getSelectedComponent = async () => {
      if (selectedComponentIDs.length > 1 || !selectedInstallation)
        return enqueueSnackbar("There was an error, please refresh and try agin", {
          variant: "error", autoHideDuration: ErrorAutoHide
        });
      let dash: IDashboard = await deepCopy(GET.dash());
      let tmpComp: IComponent = await deepCopy(dash.components.find((x) => x._id === selectedComponentIDs[0]));
      setSelectedComp(tmpComp)
    }
    getSelectedComponent()
  }, [selectedInstallation, selectedComponentIDs]);



  const handleHide = async () => {
    if (!selectedComponent) {
      return enqueueSnackbar("There was an error, please refresh and try agin", {
        variant: "error", autoHideDuration: ErrorAutoHide
      });
    }
    SET.comp({...selectedComponent, hidden: !selectedComponent.hidden})
  };

  return (
    <>
      <Button
        onClick={() => handleHide()}
        sx={{ color: "white", border: "1px solid #666" }}
        color="steelBlue"
        variant="contained"
        className="primaryButton"
      >
        {!selectedComponent?.hidden && <VisibilityIcon color="secondary"/>}
        {selectedComponent?.hidden && <VisibilityOffIcon />}
      </Button>
    </>
  );
}
